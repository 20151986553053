import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/theme";

const StyledFilterChips = styled.div`
    margin: 10px 0;
    display: flex;
    width: 100%;
    li {
        list-style-type: none;
    }
    .filter-chip-list {
        display: flex;
        gap: 10px;
        flex-flow: row wrap;
        padding-left: 17px;
        width: 89%;
    }
    .filter-chip {
        color: ${Colors.darkGrey};
        font-size: 12px;
        padding: 3px 8px;
        border-radius: 20px;
        text-transform: capitalize;
        border: 1px solid ${Colors.greyBackground};
    }
    .remove-icon {
        margin: 0px 0px 1px 2px;
        cursor: pointer;
        color: ${Colors.orange};
        font-size: 18px;
    }
    .StyledFilter {
        justify-content: space-between;
        width: 89%;
        display: flex;
    }
    .reset {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        background-color: ${Colors.orange};
        font-family: ${FontFamily.sourceSansPro};
        color: ${Colors.white01};
        padding: 1px 10px;
        border: none;
        border-radius: 5px;
        float: right;  
    }
    .disabled {
        cursor: not-allowed;
        border-radius: 5px;
        background-color: ${Colors.grey};
        opacity: 0.5;
    }

`;

export default StyledFilterChips;
