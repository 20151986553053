import React from "react";
import { StyledCount } from "./index.styles";
import { DataListingsType } from "../../store/dataListings/index.type";

interface CountProps {
    datasetName: string,
    count: number,
    offset: number,
    limit: number,
    searchTerm: string,
    dataList: DataListingsType[],
}

const Count = ({
  datasetName, count, offset, limit, searchTerm, dataList,
}: CountProps) => (
  <StyledCount>
    <span className="highlight-one">{`${datasetName.toUpperCase()}`}</span>
    {count === 0 ? ' - No results'
      : (
        <>
          {` - Showing`}
          {count > 15 && dataList?.length > 14
            ? (
              <>
                <span className="highlight-two">
                  {offset + 1}
                  -
                  {offset + limit}
                </span>
                {' results of'}
                <span className="highlight-two">
                  {count}
                </span>
              </>
            )
            : (
              <>
                <span className="highlight-two">
                  {dataList?.length}
                </span>
                {` results`}
              </>
            )}
        </>
      )}
    { searchTerm && searchTerm?.length > 0 && (
      <>
        {` for`}
        <span className="highlight-two">
          &quot;
          {searchTerm}
          &quot;
        </span>
      </>
    )}
  </StyledCount>
);

export default Count;
