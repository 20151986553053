import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { FlexColumnDiv } from "../../utils/ReusableStyles";
import { RootState } from "../../store";
import { setSelectedFilters } from "../../store/filter";

interface FilterProps {
  name: string;
  applyFilter: (appliedFilters: Record<string, unknown>) => void;
}

interface RangeFilterProps extends FilterProps {
  min: number,
  max: number,
}

const RangeFilter = ({
  name, min, max, applyFilter,
}: RangeFilterProps) => {
  const { apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );
  const { filters } = apiPayload;

  const [value, setValue] = useState<[number, number]>(filters[name] as [number, number]
    || [min, max]);

  const handleChange = _.debounce((
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < min) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - min);
        setValue([clamped, clamped + min]);
      } else {
        const clamped = Math.max(newValue[1], min);
        setValue([clamped - min, clamped]);
      }
    } else {
      setValue(newValue as [number, number]);
      applyFilter({ ...filters, [name]: newValue });
    }
  }, 500);

  return (
    <Slider
      value={value}
      min={typeof min === 'string' ? parseInt(min, 10) : min}
      max={typeof max === 'string' ? parseInt(max, 10) : max}
      onChange={handleChange}
      valueLabelDisplay="auto"
      getAriaValueText={(val: number) => `${val}`}
    />
  );
};

interface OptionInterface {
  name: string,
  id: string,
}

const MultiFilter = ({
  name, options, applyFilter, disableOnCompare,
}: { options: OptionInterface[] } & FilterProps & { disableOnCompare: string; }) => {
  const { apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );
  const dispatch = useDispatch();
  const { filters } = apiPayload;
  const thisFilterVal = filters[name] as string[] || [];
  const selectedFilters = useSelector<RootState, string[]>(
    (state) => state.filterActions.selectedFilters[name] || thisFilterVal,
  );
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name: val } = event.target;
    let selectedItems: (string)[] | ((prevState: string[]) => string[]) = [];
    if (checked) selectedItems = [...selectedFilters, val];
    if (!checked) selectedItems = _.filter(selectedFilters, (opt) => opt !== val);
    dispatch(setSelectedFilters({ name, selectedItems }));
    applyFilter({ ...filters, [name]: selectedItems });
  };

  return (
    <FlexColumnDiv alignItems="left">
      {options.map(
        ({ id, name: label }) => (
          <FormControlLabel
            key={id}
            control={(
              <Checkbox
                disabled={disableOnCompare === name && !selectedFilters?.includes(label)}
                id={id}
                checked={selectedFilters?.includes(label)}
                onChange={handleChange}
                name={label}
              />
                )}
            label={label}
          />
        ),
      )}
    </FlexColumnDiv>
  );
};

export { RangeFilter, MultiFilter };
