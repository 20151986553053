import styled from "styled-components";
import { FlexColumnDiv } from "../../utils/ReusableStyles";
import { FontFamily, Colors, BreakPoints } from "../../utils/theme";

const StyledFilters = styled(FlexColumnDiv)`
    background-color: ${Colors.lightSurface};
    .mui-accordion {
        width: -webkit-fill-available;
    }
    .MuiAccordion-root {
        box-shadow: none;
    }
    .MuiAccordion-region {
        padding: 0px 20px 0px;
    }
    .MuiFormControlLabel-label {
        font-family: ${FontFamily.sourceSansPro};
        font-size: 0.9em;
    }
    .MuiAccordionSummary-content {
        color: ${Colors.orange};
    }
    .MuiSvgIcon-root {
        font-size: 1.3em;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
        color: ${Colors.blueBackground}
    }
    .MuiTypography-root {
        font-weight: 500;
        font-size: 0.9rem;
        font-family: ${FontFamily.sourceSansPro};
    }
    .css-187mznn-MuiSlider-root {
        color: ${Colors.blueBackground}
    }
    &.hide-mobile {
        @media (max-width: ${BreakPoints.SmallDevices}) {
           display : none;
        }
    }
    .MuiFormControlLabel-root {
        width: fit-content;
    }
`;

export default StyledFilters;
