import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  selectedFilters: Record<string, string[]>;
}

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    selectedFilters: {},
  } as FilterState,
  reducers: {
    setSelectedFilters: (state, action: PayloadAction<{
      name: string; selectedItems: string[] }>) => {
      const { name, selectedItems } = action.payload;
      state.selectedFilters[name] = selectedItems;
    },
  },
});

export const { setSelectedFilters } = filterSlice.actions;
export default filterSlice.reducer;
