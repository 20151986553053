import styled from "styled-components";
import { FlexColumnDiv } from "../../../utils/ReusableStyles";
import { BreakPoints } from "../../../utils/theme";

const StyledProfile = styled(FlexColumnDiv)`
    margin: 2% 2%;
    align-items: start;
    .css-xhtqz8-MuiTableCell-root.TableCell-cellNoWrap {
        white-space: initial !important;
    }
    .row-container {
       display: flex;
       gap: 15px;
       width: 100%; 
    }
    .table-container {
        width: 50%;
    }
    .export-container {
        flex-direction: column;
        display: flex;
        width: 100%;
        padding-bottom: 4px;
        text-align: end;
    }
    .row {
       width: 100%; 
    }
    .image-style {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
        margin: 1%;
        width: 100%;
    }
`;

export default StyledProfile;
