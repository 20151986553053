import styled from "styled-components";
import { BreakPoints, FontFamily } from "../../utils/theme";

const StyledDataListingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin: 10px;
  gap: 1rem;
  font-family: ${FontFamily.sourceSansPro};
  @media screen and (max-width: ${BreakPoints.LargeDevices}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${BreakPoints.SmallDevices}) {
    grid-template-columns: repeat(1, 1fr);
    margin: 2px;
  }
`;

export default StyledDataListingContainer;
