import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownMobile } from '../../shared/components/Dropdown';
import { RootState } from '../../store';
// import { DatasetInfo } from '../../store/user/index.type';

interface SortProps {
  column: string;
  name: string;
}

const Sort = () => {
  const { currentDataset: ds } = useSelector((state: RootState) => state.userActions);
  const { apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );
  const { sort: selectedSort } = apiPayload;
  const filteredSort = ds?.sorts?.find((data: SortProps) => data.column === selectedSort);
  const { name: sortName } = filteredSort ?? { name: "" };
  return (
    <div data-testid="sort">
      {ds && (
      <>
        <Dropdown
          heading="Sort by:"
          selectedValue={sortName}
          fields={ds.sorts}
        />
        <DropdownMobile
          title="Sort"
          heading="Sort by"
          selectedValue={sortName}
          fieldsMobile={ds.sorts}
        />
      </>
      )}
    </div>
  );
};

export default Sort;
