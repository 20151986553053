import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../api/dataApi";
import { BranchesInterface } from "./index.type";

export const fetchAsyncBranches: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("branches/fetchAsyncBranches", post);

const initialState : BranchesInterface = {
  branches: {
    rows: [{
      "Branch Name": "",
      Address: "",
      Metrics: {
        Year: 1234,
        "Total Assets ($)": "",
        "Total Deposits ($)": "",
        "Total Domestic Deposits ($)": "",
        "Total Deposits Branch ($)": "",
      },
    }],
    count: 0,
  },
  apiPayload: {
    offset: 0,
    limit: 20,
  },
  loading: false,
  error: {},
};
const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    branches: (state, action) => {
      state.branches.rows.push = action.payload.branches;
    },
    pagination: (state, { payload }) => {
      state.apiPayload.offset = (payload);
    },
  },
  extraReducers: {
    [fetchAsyncBranches.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncBranches.fulfilled]: (state, { payload }) => {
      return { ...state, branches: payload, loading: false };
    },
    [fetchAsyncBranches.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const branchesActions = branchesSlice.actions;
export default branchesSlice.reducer;
