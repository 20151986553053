import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../store';
import { DataItem } from '../../../shared/components/Export/index.type';
import NavTab from '../../../shared/components/NavTab';
import { formatDataWithOuterKey, formatGraphData } from '../../../pages/PeerAnalytics';

const getFormattedData = (rows: DataItem[]) => {
  if (!rows || rows?.length === 0) {
    return { rows: {} };
  }
  const firstObjectKeys = Object.keys(rows[0]);
  const keysToExclude = ["Reported Date", "id", "fiId"];
  const transformedData = firstObjectKeys
    .filter((key) => !keysToExclude.includes(key))
    .reduce((result: DataItem, key) => {
      result.rows[key] = rows?.map((row) => ({
        'Reported Date': row['Reported Date'],
        ...row[key],
      }));
      return result;
    }, { rows: {} });

  return transformedData;
};

export const removeAnalyticsKeys = (obj: DataItem) => {
  return Object.keys(obj).reduce((newObj:DataItem, key) => {
    if (!key.includes("Analytics")) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
};

const Metrics = () => {
  const { metrics } = useSelector(
    (state: RootState) => state.metricsActions,
  );
  const { rows } = metrics;
  const formattedData = getFormattedData(rows);
  const { state } = useLocation();
  const cardIds = state?.map((item: { cardId: string; }) => item.cardId);
  const formattedTabData = formatDataWithOuterKey(formatGraphData(rows, cardIds, state));
  const tabItems = Object.keys(formattedData?.rows)?.map((key) => ({
    eventKey: key.toLocaleUpperCase(),
    label: key.toLocaleUpperCase(),
    content: formattedData?.rows[key]?.map((item: DataItem) => removeAnalyticsKeys(item)),
    graphContent: formattedTabData[key],
  }));

  return (
    <NavTab
      tabItems={tabItems}
      tabName="Metrics"
    />
  );
};

export default Metrics;
