import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/dataApi";
import { MergerInterface } from "./index.type";

export const fetchAsyncMerger: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("merger/fetchAsyncMerger", get);

const initialState : MergerInterface = {
  merger: {
    predecessors: [],
    relationships: [],
    successors: [],
  },
  loading: false,
  error: {},
};
const mergerSlice = createSlice({
  name: "merger",
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchAsyncMerger.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncMerger.fulfilled]: (state, { payload }) => {
      return { ...state, merger: payload, loading: false };
    },
    [fetchAsyncMerger.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const mergerActions = mergerSlice.actions;
export default mergerSlice.reducer;
