import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  StyledDataCard, StyledDataTitle,
  StyledChip, StyledDataContent, StyledSubContent, StyledViewButton, StyledProperty,
} from './index.styles';
import { LocationType } from '../DataListingsTile/DataListingType';
import { convertKeyValuePairFormat, formatUrl, replaceNullWithNotAvailable } from '../../utils/utils';
import { PropertyType } from '../../store/dataListings/index.type';
import { CREDITUNION, BANK } from '../../utils/Images';
import Button from '../../shared/components/Button';
import { dataListingsActions } from '../../store/dataListings';
import { DataItem } from '../../shared/components/Export/index.type';
import { RootState } from '../../store';
import { setSelectedFilters } from '../../store/filter';
import { setSortOption } from '../../store/sort';

export interface SelectedProps {
  id: string,
  name: string,
  locations: LocationType,
  type: string,
}

interface DataCardProps {
  id: string;
  name: string;
  locations: LocationType;
  type: string;
  website: string;
  property: PropertyType;
  isSelected: boolean;
  onSelect: (data: SelectedProps) => void;
  selectedCards: string[];
  setAppliedFilters: (filters: DataItem[]) => void;
  appliedFilters: DataItem[];
  setSelectedFilterChips: (filterchips: DataItem[]) => void;
  setParsedCompareData: React.Dispatch<React.SetStateAction<DataItem[]>>;
}

const DataCard = ({
  id,
  name,
  locations,
  type,
  website,
  property,
  isSelected,
  onSelect,
  selectedCards,
  setAppliedFilters,
  appliedFilters,
  setSelectedFilterChips,
  setParsedCompareData,
}: DataCardProps) => {
  const {
    addressLineOne, addressLineTwo, city: { name: cityName },
    county: { name: countyName }, zip, state: { abbrevation }, country,
  } = locations;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );

  const handleCompareClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!appliedFilters.includes(property["Asset Group"])) {
      const newAppliedFilters = [...appliedFilters, property["Asset Group"]];
      setAppliedFilters(newAppliedFilters);
      const { filters } = apiPayload;
      const filterPayload: DataItem = {
        ...filters,
        type: [type],
        asset_group: [property["Asset Group"]],
      };
      setSelectedFilterChips(convertKeyValuePairFormat(filterPayload));
      dispatch(dataListingsActions.filter(filterPayload));
      Object.keys(filterPayload).forEach((key) => {
        const selectedItems = filterPayload[key];
        dispatch(setSelectedFilters({ name: key, selectedItems }));
      });
      dispatch(dataListingsActions.Sort([
        "property",
        "total_assets",
        "DESC NULLS LAST",
      ]));
      dispatch(setSortOption("Highest Asset"));
    }
    onSelect({
      id, name, locations, type,
    });
    const selectedCompareData: DataItem = {
      id,
      name,
      locations,
      type,
      website,
      property,
    };
    localStorage.setItem("selectedCompareData", JSON.stringify(selectedCompareData));
    setParsedCompareData((prevData: DataItem[]) => [...prevData, selectedCompareData]);
  };
  return (
    <StyledDataCard data-testid="data-card">
      <div
        onKeyDown={undefined}
        tabIndex={0}
        role="button"
        onClick={() => { navigate(`/${id}/${formatUrl(name)}`, { state: [{ cardId: id, name }] }); }}
        className="card-link"
      >
        <div className="title">
          <StyledDataTitle>
            {name}
          </StyledDataTitle>
          <StyledChip>
            <Chip
              avatar={<img alt="type" src={type === "Bank" ? BANK : CREDITUNION} />}
              label={type}
              size="small"
              className="type-chip"
            />
          </StyledChip>
        </div>
        <StyledDataContent>
          <div>{addressLineOne}</div>
          <div>{ addressLineTwo}</div>
          {(cityName || countyName || abbrevation || zip || country) && (
          <div>
            {cityName || countyName}
            {cityName && ','}
            {' '}
            {abbrevation}
            {abbrevation && ','}
            {' '}
            {zip}
            {zip && ','}
            {' '}
            { country }
          </div>
          )}
        </StyledDataContent>
        <a href={`https://${website}`} className="card-link">
          {website}
        </a>
        <StyledSubContent>
          {Object.entries(property)
            ?.slice(0, 3)
            .map(([key, value]) => (
              <StyledProperty key={key}>
                <div className="key-format">
                  {key}
                </div>
                <div>
                  {replaceNullWithNotAvailable(value)}
                </div>
              </StyledProperty>
            ))}
        </StyledSubContent>
        <StyledViewButton>
          <Button
            type="submit"
            className={`secondary format-button ${(isSelected || selectedCards?.length === 3) && 'disabled'}`}
            onClick={handleCompareClick}
            name={isSelected ? "Selected" : "Compare"}
            disabled={(isSelected || selectedCards?.length === 3)}
          />
          <Button
            type="submit"
            className="primary format-button"
            onClick={() => { navigate(`/${id}/${formatUrl(name)}`, { state: [{ cardId: id, name }] }); }}
            name="View Details"
          />
        </StyledViewButton>
      </div>
    </StyledDataCard>
  );
};

export default DataCard;
