import { DataItem } from "../shared/components/Export/index.type";
import { Colors } from "./theme";

const formatUrl = (url: string) => {
  return url.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '').replace(/\s+/g, '-').toLowerCase();
};

const convertCamelCaseToUpperCase = (key: string) => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str: string) => str?.toUpperCase());
};

const replaceNullWithEmptySpace = (value: string) => {
  return value !== null ? value : "";
};

const formatPhoneNumber = (value: string) => {
  return value.replace(/(\d{3})(\d{3})?(\d{0,4})$/, (match, p1, p2, p3) => {
    let formatted = `${p1}`;
    if (p2) {
      formatted += `-${p2}`;
    }
    if (p3) {
      formatted += p3.length === 4 ? `-${p3}` : p3;
    }
    return formatted;
  });
};

const replaceNullWithNotAvailable = (value: string) => {
  return value !== null ? value : "N/A";
};

const formatTableRows = (params: object) => {
  const rows = Object.entries(params)?.map(([key, value]) => ({
    Key: key,
    value: value !== null ? value : "—",
  }));
  return rows;
};

const replaceHyphenWithSpace = (value: string) => {
  return value !== "—" ? value : "";
};

const formatChartLabel = (value: string) => {
  const formattedLabel = value?.replace(/\([^)]*\)/, '').trim();
  return formattedLabel;
};

const formatMonthYear = (dateStr: string, locale: Intl.LocalesArgument) => {
  const date = new Date(dateStr);
  const month = date.toLocaleString(locale, { month: 'short' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};
const convertKeyValuePairFormat = (data: DataItem) => {
  const formattedData = Object.entries(data).flatMap(([key, values]) => {
    const updatedKey = key.includes('_') ? key.replace(/_.*/, '') : key;
    return values.map((value: string) => ({ key: updatedKey, value }));
  });
  return formattedData;
};
const checkMaximumValueUnit = (value: number) => {
  let unit = '';
  if (value < 1000) {
    unit = 'Thousands';
  } else if (value >= 1000 && value < 1000000) {
    unit = 'Million';
  } else if (value >= 1000000) {
    unit = 'Billion';
  }
  return unit;
};

const generateChartColor = (index: number) => {
  const colors = [
    { borderColor: Colors.lightRed, backgroundColor: Colors.lightRed },
    { borderColor: Colors.lightBlue, backgroundColor: Colors.lightBlue },
    { borderColor: Colors.lightGreen, backgroundColor: Colors.lightGreen },
  ];

  return colors[index % colors.length];
};
const replaceBooleanValue = (value: DataItem) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
};

export {
  formatUrl, convertCamelCaseToUpperCase, formatPhoneNumber,
  formatTableRows, replaceNullWithEmptySpace, replaceNullWithNotAvailable, formatMonthYear,
  generateChartColor, formatChartLabel, checkMaximumValueUnit, convertKeyValuePairFormat,
  replaceBooleanValue, replaceHyphenWithSpace,
};
