import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import StyledListingDetailContainer from './index.styles';
import Location from '../../components/Detail/Location';
import { RootState } from '../../store';
import Profile from '../../components/Detail/Profile';
import Services from '../../components/Detail/Services';
import Merger from '../../components/Detail/Merger';
import Loader from '../../shared/components/Loader';
import { fetchAsyncProfile } from '../../store/profile';
import { fetchAsyncBranches } from '../../store/branches';
import { fetchAsyncServices } from '../../store/services';
import { fetchAsyncMerger } from '../../store/merger';
import { ServiceProps } from '../../components/Detail/Services/index.type';
import { StyledHighlight } from '../../components/Detail/Location/index.styles';
import { BranchInfoProps } from '../../components/Detail/Location/index.type';
import { convertCamelCaseToUpperCase } from '../../utils/utils';
import Breadcrumb from '../../components/Breadcrumb';
import Metrics from '../../components/Detail/Metrics';
import { fetchAsyncMetrics } from '../../store/metrics';
import { DataItem } from '../../shared/components/Export/index.type';

const getNavigation = (
  datasetName: string,
  profileName: string,
) => {
  return [
    {
      displayName: convertCamelCaseToUpperCase(datasetName),
      url: `/`,
    },
    {
      displayName: profileName,
    },
  ];
};

const BranchInfo = ({ branchName, isMainBranch }: BranchInfoProps) => {
  if (isMainBranch) {
    return (
      <StyledHighlight>
        <div className="styled-name">{branchName}</div>
        <Chip
          label="Main Branch"
          size="small"
          color="primary"
          className="type-chip"
        />
      </StyledHighlight>
    );
  }
  return (<span>{branchName}</span>);
};

const index = () => {
  const [activeTab, setActiveTab] = useState<string>(() => {
    const storedTab = localStorage.getItem('activeTab');
    return storedTab || 'Profile';
  });
  const { loading, profile } = useSelector(
    (state: RootState) => state.profileActions,
  );
  const { branches, apiPayload } = useSelector(
    (state: RootState) => state.branchesActions,
  );
  const { services } = useSelector(
    (state: RootState) => state.servicesActions,
  );
  const { merger } = useSelector(
    (state: RootState) => state.mergerActions,
  );
  const { metrics } = useSelector(
    (state: RootState) => state.metricsActions,
  );

  const url = window.location.pathname;
  const [, fiId] = url.split("/");
  const {
    currentDataset: { id: datasetId, name: datasetName },
  } = useSelector((state: RootState) => state.userActions);

  const { Name: profileName, "Reported Date": reportedDate } = profile;

  const dispatch = useDispatch();

  const handleRenderBranchInfo = ({ branchName, isMainBranch }: BranchInfoProps) => {
    return <BranchInfo branchName={branchName} isMainBranch={isMainBranch} />;
  };

  useEffect(() => {
    dispatch(fetchAsyncProfile(`details/${datasetId}/demographic-data/${fiId}`));
    dispatch(fetchAsyncBranches({ endpoint: `details/${datasetId}/branches/${fiId}`, apiPayload }));
    dispatch(fetchAsyncServices(`details/${datasetId}/services/${fiId}`));
    dispatch(fetchAsyncMerger(`details/${datasetId}/mergers-and-acquisitions/${fiId}`));
    dispatch(fetchAsyncMetrics(`details/${datasetId}/metrics?ids=${fiId}`));
  }, [fiId, datasetId]);

  const handleTabChange = (tabId: React.SetStateAction<string>|any) => {
    setActiveTab(tabId);
    localStorage.setItem('activeTab', tabId);
  };
  return (
    <StyledListingDetailContainer>
      {(loading) && <Loader loading={loading} />}
      {!loading && (
        <div className="tab-container">
          <Breadcrumb navigation={getNavigation(
            datasetName,
            profileName,
          )}
          />
          <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
            <div className="main-tab-container">
              <Nav variant="tabs">
                {profile && (
                <Nav.Item>
                  <Nav.Link eventKey="Profile">Demographics</Nav.Link>
                </Nav.Item>
                )}
                { services && !Object.keys(services).every(
                  (key) => _.isEmpty((services as DataItem)[key]),
                ) && (
                <Nav.Item>
                  <Nav.Link eventKey="services">Services</Nav.Link>
                </Nav.Item>
                ) }
                { branches?.rows?.length > 0 && (
                <Nav.Item>
                  <Nav.Link eventKey="Locations">Locations</Nav.Link>
                </Nav.Item>
                )}
                { merger && (!_.isEmpty(
                  merger.predecessors,
                ) || !_.isEmpty(merger.successors)
                || !_.isEmpty(merger.relationships)) && (
                  <Nav.Item>
                    <Nav.Link eventKey="Merger">Merger and Acquisition</Nav.Link>
                  </Nav.Item>
                )}
                { metrics?.rows?.length > 0 && (
                <Nav.Item>
                  <Nav.Link eventKey="Metrics">Metrics</Nav.Link>
                </Nav.Item>
                )}
              </Nav>
              { (reportedDate !== null) && (
                <div className="year-container">
                  <span className="label">Reported Date:</span>
                  {reportedDate}
                </div>
              )}
            </div>
            <Tab.Content>
              { profile && (
              <Tab.Pane eventKey="Profile">
                <Profile profile={profile} />
              </Tab.Pane>
              )}
              <Tab.Pane eventKey="services">
                {services && !Object.keys(services).every(
                  (key) => _.isEmpty((services as ServiceProps|any)[key]),
                ) && (
                <Services services={services} />
                )}
              </Tab.Pane>
              { branches?.rows?.length > 0 && (
              <Tab.Pane eventKey="Locations">
                <Location
                  fiId={fiId}
                  datasetId={datasetId}
                  handleRenderBranchInfo={handleRenderBranchInfo}
                />
              </Tab.Pane>
              )}
              { merger && (!_.isEmpty(
                merger.predecessors,
              ) || !_.isEmpty(merger.successors)
                || !_.isEmpty(merger.relationships)) && (
                  <Tab.Pane eventKey="Merger">
                    <Merger merger={merger} />
                  </Tab.Pane>
              )}
              { metrics?.rows?.length > 0 && (
              <Tab.Pane eventKey="Metrics">
                <Metrics />
              </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </StyledListingDetailContainer>
  );
};

export default index;
export {
  getNavigation,
};
