import React from 'react';
import { StyledNoResultFound, StyledNoResultImageContainer } from './index.styles';
import { StyledDataTitle } from '../title';

interface NoResultProps {
  image: string,
  title: string,
}

const NoResultFound : React.FC<NoResultProps> = ({
  image, title,
}) => {
  return (
    <StyledNoResultFound data-testid="no-result">
      <StyledNoResultImageContainer>
        <img src={`${image}`} alt="no result" className="noresult-icon" />
      </StyledNoResultImageContainer>
      <StyledDataTitle>{title}</StyledDataTitle>
    </StyledNoResultFound>
  );
};

export default NoResultFound;
