import {
  useDispatch, useSelector,
} from 'react-redux';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { setSortOption } from '../../../store/sort';
import { RootState } from '../../../store';
import { RiArrowDropDownLine } from "../../../utils/icons";
import {
  StyledDropDown, StyledDropdownDesktop, StyledDropDownButton,
  StyledDropDownContainer, StyledDropdownList, StyledMobileLabel,
} from './index.styles';
import { CLOSEIMAGE, TICKSORTIMAGE } from '../../../utils/Images';
import Button from '../Button';
import { dataListingsActions } from '../../../store/dataListings';
import ModalComponent from '../Modal/Modal';
import { KeyCodeProps } from '../../../components/Search/SearchType';

interface DropDownFieldProps {
    id: string,
    name: string,
    column: string,
    order: number,
  }

  interface DropdownProps {
    heading: string;
    fields: DropDownFieldProps[];
    selectedValue: string;
  }
export interface EventProps {
    target: HTMLDivElement | any;
}
interface DropdownListProps {
    isClicked: boolean;
    options: DropDownFieldProps[];
    updatedValue: string;
    selectedValue: string;
    handleKeyDown?: (event: KeyCodeProps) => void;
}

const DropdownList: React.FC<DropdownListProps> = ({
  isClicked,
  options,
  updatedValue,
  selectedValue,
  handleKeyDown,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(selectedValue)) {
      dispatch(setSortOption(selectedValue));
    }
  }, [selectedValue]);

  return (
    <StyledDropDownContainer>
      {isClicked && (
      <div className="dropdown-field">
        {options?.map((option: DropDownFieldProps) => {
          return (
            <StyledDropdownList
              key={option.id}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onClick={() => {
                dispatch(setSortOption(option?.name));
                dispatch(dataListingsActions.Sort(option.column));
              }}
              className="dropdown-field-button"
            >
              {updatedValue === option.name && (
              <img
                src={TICKSORTIMAGE}
                alt="tick"
                className="tick-mark"
              />
              )}
              <div
                className={
                      updatedValue === option.column
                        ? "active-field"
                        : "inactive-field"
                    }
              >
                {option.name}
              </div>
            </StyledDropdownList>
          );
        })}
      </div>
      )}
    </StyledDropDownContainer>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  heading,
  fields,
  selectedValue,
}) => {
  const sortOption = useSelector((state: RootState) => state.sortActions.sortOption);
  const [dropDown, setDropDown] = useState(false);
  const closeRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!closeRef.current?.contains(event.target)) {
        setDropDown(false);
      }
    });
  });
  const handleKeyDown = (event: KeyCodeProps) => {
    if (event.keyCode === 13) {
      if (selectedIndex >= 0 && selectedIndex < fields?.length) {
        dispatch(setSortOption(fields[selectedIndex]?.name));
        dispatch(dataListingsActions.Sort(fields[selectedIndex]?.column));
        setDropDown(false);
        setSelectedIndex(-1);
      }
    } else if (event.keyCode === 40) {
      setSelectedIndex((prevIndex) => (prevIndex < fields.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (event.keyCode === 38) {
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    }
  };

  return (
    <StyledDropDown>
      <StyledDropdownDesktop
        ref={closeRef}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        onClick={() => setDropDown(!dropDown)}
      >
        <div className="drop-left">
          <div className="drop-text">{heading}</div>
          <div className="dropdown-button">
            {_.startCase(_.camelCase(sortOption))}
          </div>
        </div>
        <RiArrowDropDownLine className="dropdown-icon" />
      </StyledDropdownDesktop>
      <DropdownList
        options={fields}
        isClicked={dropDown}
        updatedValue={sortOption}
        selectedValue={selectedValue}
        handleKeyDown={handleKeyDown}
      />
    </StyledDropDown>
  );
};

interface DropdownMobileProps {
    fieldsMobile: DropDownFieldProps[];
    selectedValue: string;
    title: string;
    heading: string;
}

const DropdownMobile: React.FC<DropdownMobileProps> = ({
  heading,
  fieldsMobile,
  title,
  selectedValue,
}) => {
  const [modal, setModal] = useState(false);
  const sortOption = useSelector((state: RootState) => state.sortActions.sortOption);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!modalRef.current?.contains(event.target)) {
        setModal(false);
      }
    });
  });
  return (
    <div ref={modalRef}>
      <StyledDropDownButton>
        <Button
          type="button"
          className="primary me-0 ms-0"
          onClick={() => {
            setModal(!modal);
          }}
          name={title}
        />
      </StyledDropDownButton>
      {modal && (
      <ModalComponent isOpen={modal} modalHeight="60%" modalWidth="80%">
        <StyledDropDownContainer>
          <StyledMobileLabel>
            {heading}
            <img src={CLOSEIMAGE} alt="close" className="close" />
          </StyledMobileLabel>
          <DropdownList
            options={fieldsMobile}
            isClicked={modal}
            updatedValue={sortOption}
            selectedValue={selectedValue}
          />
        </StyledDropDownContainer>
      </ModalComponent>
      )}
    </div>
  );
};

const defaultHandleKeyDown = () => { return null; };

DropdownList.defaultProps = {
  handleKeyDown: defaultHandleKeyDown,
};

export { Dropdown, DropdownMobile };
