import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/dataApi";
import { MetricsInterface } from "./index.type";

export const fetchAsyncMetrics: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("metrics/fetchAsyncMetrics", get);

const initialState : MetricsInterface = {
  metrics: {
    rows: [{}],
    count: 0,
  },
  loading: false,
  error: {},
};
const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    metrics: (state, action) => {
      state.metrics.rows.push = action.payload.metrics;
    },
  },
  extraReducers: {
    [fetchAsyncMetrics.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncMetrics.fulfilled]: (state, { payload }) => {
      return { ...state, metrics: payload, loading: false };
    },
    [fetchAsyncMetrics.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const metricsActions = metricsSlice.actions;
export default metricsSlice.reducer;
