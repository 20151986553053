import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { DataItem } from '../Export/index.type';
import { generateChartColor } from '../../../utils/utils';
import options from './ChartOptions';

interface ChartProps {
    chartType: string,
    data: DataItem,
    label: string[],
    tabName?: string;
}
interface DataSetProps {
    label: string;
    data: DataItem;
    borderColor: string;
    backgroundColor: string;
    barPercentage?: number;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const formatDataValue = (valueData: DataItem, labels: string[], tabName?: string) => {
  const transformedDataArray = Object.entries(valueData)?.map(([key, value], index) => {
    const dataset: DataSetProps = {
      label: key,
      data: value,
      ...generateChartColor(index),
    };
    if (tabName === 'metrics') {
      dataset.barPercentage = 0.5;
    }
    return dataset;
  });

  const data = {
    labels,
    datasets: transformedDataArray,
  };
  return data;
};

const Chart = ({
  chartType, data, label, tabName,
}: ChartProps) => {
  return (
    <div>
      { chartType === "bar" ? <Bar options={options(chartType, data)} data={formatDataValue(data, label, tabName)} />
        : <Line options={options(chartType, data)} data={formatDataValue(data, label)} />}
    </div>
  );
};

Chart.defaultProps = {
  tabName: "",
};
export default Chart;
