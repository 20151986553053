import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { branchesActions, fetchAsyncBranches } from '../../../store/branches';
import Pagination from '../../Pagination';
import { StyledLocationContainer, StyledMultiLevelTableContainer } from './index.styles';
import { GREENTICKIMAGE, REDCROSSIMAGE } from '../../../utils/Images';
import { RootState } from '../../../store';
import Loader from '../../../shared/components/Loader';
import { GridWithMultiLevelTable } from '../../../shared/components/Grid';
import { ColumnProps, ToggleProps } from '../../../shared/components/Grid/index.type';
import {
  Metric, MetricsRowProps, LocationProps,
} from './index.type';
import { DataItem } from '../../../shared/components/Export/index.type';
import Export from '../../../shared/components/Export';
import { getColumns, getRows } from '../Merger';

export const getImageTag = (condition: boolean) => {
  return condition ? (
    <img src={GREENTICKIMAGE} alt="green-tick" className="image-style" />
  ) : (
    <img src={REDCROSSIMAGE} alt="red-cross" className="image-style" />
  );
};

const TooltipCell = ({ ...props }: ToggleProps) => {
  const rowDetailEnabled = props?.row?.Metrics !== undefined && props?.row?.Metrics?.length > 0;
  const handleToggle = rowDetailEnabled ? props?.onToggle : () => { return null; };

  return (
    <div className={`toggle-cell ${rowDetailEnabled ? 'enabled' : 'disabled'}`}>
      {!rowDetailEnabled ? (
        <div>
          <TableRowDetail.ToggleCell {...props} onToggle={handleToggle} />
        </div>
      ) : (
        <TableRowDetail.ToggleCell {...props} onToggle={handleToggle} />
      )}
    </div>
  );
};

const handleRenderToggle = ({ ...props }: ToggleProps) => {
  return <TooltipCell {...props} />;
};

const RowDetail = ({ row }: MetricsRowProps) => {
  let metricsColumns: string | ColumnProps[] = [];

  if (row?.Metrics && row.Metrics.length > 0) {
    metricsColumns = Object?.keys(row?.Metrics[0]).map((key) => ({
      name: key,
      title: key,
    }));
  }

  let metricsData: DataItem[] = [];

  if (row?.Metrics) {
    metricsData = row.Metrics.map((metric) => {
      const transformedMetric: Metric = {};

      Object.keys(metric).forEach((key) => {
        transformedMetric[key] = metric[key] !== null ? metric[key] : "—";
      });

      return transformedMetric;
    });
  }

  return (
    <StyledMultiLevelTableContainer>
      {metricsColumns.length > 0 ? (
        <Grid rows={metricsData} columns={metricsColumns}>
          <Table />
          <TableHeaderRow />
        </Grid>
      ) : (
        <div className="no-results-text">No Metrics Available</div>
      )}
    </StyledMultiLevelTableContainer>
  );
};

const Location = ({ fiId, datasetId, handleRenderBranchInfo } : LocationProps) => {
  const { branches, apiPayload, loading } = useSelector(
    (state: RootState) => state.branchesActions,
  );
  const dispatch = useDispatch();
  const { rows: data, count } = branches;
  const formattedData = data?.flatMap((branch) => {
    const { Metrics, isMainOffice, ...restBranch } = branch;

    if (Metrics && Metrics?.length > 0) {
      return Metrics?.map((metric: { [x: string]: string; Year: string }) => {
        const formattedMetric = {
          ...restBranch,
          ...metric,
        };
        if (isMainOffice) {
          delete formattedMetric.isMainOffice;
        }
        return formattedMetric;
      });
    }
    return [restBranch];
  });

  const isMetricColumn = Object.keys(data[0])?.includes('Metrics');

  let columns: ColumnProps[] = [];

  if (data !== null && data.length > 0) {
    columns = Object.keys(data[0])
      .filter((key) => key !== 'Metrics' && key !== 'isMainOffice')
      .map((key) => ({
        name: key,
        title: key,
        getCellValue: (row: { [x: string]: boolean; }) => {
          if (key === 'Branch Name') {
            const branchName = row[key]?.toString();
            if (row?.isMainOffice) {
              return (
                handleRenderBranchInfo({
                  branchName: row[key]?.toString(),
                  isMainBranch: row?.isMainOffice,
                })
              );
            }
            return branchName;
          } if (typeof row[key] === 'boolean') {
            return getImageTag(row[key]);
          } if (row[key] === null) {
            return "—";
          }
          return row[key];
        },
      }));
  }

  useEffect(() => {
    dispatch(fetchAsyncBranches({ endpoint: `details/${datasetId}/branches/${fiId}`, apiPayload }));
  }, [fiId, datasetId, apiPayload]);

  return (
    <StyledLocationContainer style={{
      width: isMetricColumn ? "96%" : "100%",
    }}
    >
      {loading ? <Loader loading={loading} />
        : (
          <>
            <Export rowData={getRows(formattedData)} columnData={getColumns(formattedData)} fileName="Locations" />
            <GridWithMultiLevelTable
              rowData={data}
              columnData={columns}
              isMetricColumn={isMetricColumn}
              RowDetail={RowDetail}
              handleRenderToggle={handleRenderToggle}
            />
          </>
        )}
      { count > 49 && (
        <div className="pagination">
          <Pagination
            count={count}
            limit={apiPayload.limit}
            offset={apiPayload.offset}
            action={branchesActions}
          />
        </div>
      )}
    </StyledLocationContainer>
  );
};

export default Location;
