import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../../utils/theme";

const StyledLocationContainer = styled.div`
    margin: 2% 2%;
    font-family: ${FontFamily.sourceSansPro};
    .toggle-cell {
        position: relative;
        &:hover {
            &.disabled::before {
                content: "No Metrics Available";
                position: absolute;
                top: 38px;
                left: 110%;
                transform: translateX(-50%);
                width: 100px;
                height: 20px;
                padding: 3px;
                font-size: 10px;
                background-color: ${Colors.grey};
                color: ${Colors.white01};
                border-radius: 5px;
                display: block;
                text-align: center;
                z-index: 2;
            }
            &.disabled::after {
                content: "";
                position: absolute;
                top: 32px;
                left: 35%;
                border-width: 0 8px 8px 8px;
                border-style: solid;
                border-color: transparent transparent  ${Colors.grey} transparent;
                display: block;
                z-index: 1;
            }
        }
   }
    .image-style {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
    .pagination {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
        width: 100% !important;
        padding-right: 20px;
    }
`;

const StyledHighlight = styled.div`
    display: flex;
    align-items: center;
    gap: 2%;
    .type-chip {
        color: ${Colors.white};
        font-size: 12px;
        background-color: ${Colors.darkOrange};
        height: fit-content;
    }
    .styled-name {
        white-space: initial;
    }
`;

const StyledMultiLevelTableContainer = styled.div`
   width: 100%;
   th {
        background-color: ${Colors.white} !important;
        font-size: 14px;
        font-weight: 600;
        white-space: initial;
        text-align: center;
        height: 20px;
        border-color: ${Colors.orange} !important;
        color: ${Colors.background} !important;
        border-style: solid;
        border-width: 0.5px;
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1ecsaki-MuiTable-root.Table-table {
        table-layout: auto;
        border-collapse: collapse;
    }
    tbody, td, tfoot, tr {
        border-color: ${Colors.tableBorder};
        border-style: solid;
        text-align: center !important;
        border-width: 0.1px;
    }
   .no-results-text {
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        font-family: ${FontFamily.sourceSansPro};
        color: ${Colors.darkSurface};

    }
    .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
        color: ${Colors.background} !important;
        &:hover {
            color: ${Colors.orange};
        }
    }
`;

export {
  StyledLocationContainer, StyledMultiLevelTableContainer, StyledHighlight,
};
