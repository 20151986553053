import React from 'react';
import StyledProfile from './index.styles';
import { getImageTag } from '../Location';
import { GridWithVerticalTable } from '../../../shared/components/Grid';
import { formatTableRows } from '../../../utils/utils';
import { ProfileType } from './index.type';
import Export from '../../../shared/components/Export';

const Profile = ({ profile }: {
  profile: ProfileType}) => {
  const columns = [
    { name: 'Key', title: 'Key' },
    {
      name: 'value',
      title: 'value',
      getCellValue: (row: { value: boolean; }) => {
        if (typeof row.value === 'boolean') {
          return getImageTag(row.value);
        }
        return row.value;
      },
    },
  ];
  const filteredRows = formatTableRows(profile)?.filter(
    (row) => row.Key !== 'Reported Date',
  );
  const totalRows = filteredRows;
  const halfRowCount = Math.ceil(totalRows.length / 2);
  return (
    <StyledProfile>
      <div className="export-container">
        <Export rowData={totalRows} columnData={columns} fileName="Demographics" />
        <div className="row-container">
          {[0, 1].map((index) => (
            <div key={index} className="table-container">
              <GridWithVerticalTable
                rowData={totalRows.slice(index * halfRowCount, (index + 1) * halfRowCount)}
                columnData={columns}
              />
            </div>
          ))}
        </div>
      </div>
    </StyledProfile>
  );
};

export default Profile;
