import styled, { css } from "styled-components";
import { FontFamily } from "./theme";

interface Props {
   alignItems? : string
}

const FlexRowCSS = css`
    display: flex;
    flex-direction: row;
`;

const FlexColumnCSS = css<Props>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignItems || "center"};
`;

const CenterFlex = css`
    align-items: center;
    justify-content: center;
`;
const FlexRowDiv = styled.div`
    ${FlexRowCSS};
    ${CenterFlex}
`;

const FlexColumnDiv = styled.div`
    ${FlexColumnCSS}
`;

const TitleDiv = styled.div`
    text-align: center;
    font-weight: 600;
    font-family: ${FontFamily.sourceSansPro};
`;

export { FlexRowDiv, FlexColumnDiv, TitleDiv };
