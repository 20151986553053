import styled from "styled-components";
import { FlexRowDiv } from "../../../utils/ReusableStyles";

const StyledChartLabel = styled(FlexRowDiv)`
    margin-bottom: 25px;
    gap: 30px;
    .label {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 3px;
    } 
    .label-item {
        display: flex;
        margin-bottom: 6px;
        align-items: center;
    }
   
`;

export default StyledChartLabel;
