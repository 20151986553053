import React from 'react';
import { StyledTableContainer, StyledTableHeading, StyledHeader } from './index.styles';
import { convertCamelCaseToUpperCase, replaceNullWithEmptySpace } from '../../../utils/utils';
import { GridWithHorizontalTable } from '../../../shared/components/Grid';
import { MergerTypes } from './index.type';
import Export from '../../../shared/components/Export';
import { DataItem } from '../../../shared/components/Export/index.type';

export const getColumns = (data: object[]) => {
  if (data !== null && data.length > 0) {
    const columns = Object.keys(data[0]);
    return columns.map((column) => ({ name: column, title: column }));
  }
  return [];
};

export const getRows = (data: DataItem[]) => {
  if (data !== null && data.length > 0) {
    const columnsData = Object.keys(data[0]);
    const rows = data.map((item) => {
      return columnsData.reduce((acc: DataItem, column: string) => {
        acc[column] = replaceNullWithEmptySpace(item[column]);
        return acc;
      }, {});
    });
    return rows;
  }
  return [];
};

const index = ({ merger } :{ merger: MergerTypes}) => {
  const generateGrid = (sectionKey: keyof MergerTypes) => {
    if (merger[sectionKey]?.length > 0) {
      return (
        <>
          <StyledHeader>
            <StyledTableHeading>{convertCamelCaseToUpperCase(sectionKey)}</StyledTableHeading>
            <Export
              rowData={getRows(merger[sectionKey])}
              columnData={getColumns(merger[sectionKey])}
              fileName={convertCamelCaseToUpperCase(sectionKey)}
            />
          </StyledHeader>
          <StyledTableContainer>
            <GridWithHorizontalTable
              key={sectionKey}
              rowData={getRows(merger[sectionKey])}
              columnData={getColumns(merger[sectionKey])}
              fileName={convertCamelCaseToUpperCase(sectionKey)}
            />
          </StyledTableContainer>
        </>
      );
    }
    return null;
  };
  return (
    <>
      {(Object.keys(merger) as Array<keyof MergerTypes>).map(
        (sectionKey: keyof MergerTypes) => generateGrid(sectionKey),
      )}
    </>
  );
};

export default index;
