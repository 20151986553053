import { checkMaximumValueUnit } from "../../../utils/utils";
import { DataItem } from "../Export/index.type";

const options = (chartType: string, data: DataItem) => {
  const extractedValues = Object.values(data);
  const combinedArray = extractedValues.flat();
  const commonOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Quarters',
        },
      },
    },
  };

  if (chartType === 'line') {
    const dataValues = combinedArray.slice();
    dataValues?.sort((a, b) => a - b);
    const minDataValue = dataValues[0];
    const maxDataValue = dataValues[dataValues.length - 1];
    const paddingPercentage = 0.1;
    const dataRange = maxDataValue - minDataValue;
    const padding = dataRange * paddingPercentage;
    const suggestedMin = minDataValue - padding;
    const suggestedMax = maxDataValue + padding;
    const numTicks = 5;
    const tickSpacing = (suggestedMax - suggestedMin) / (numTicks - 1);
    return {
      ...commonOptions,
      scales: {
        ...commonOptions.scales,
        y: {
          title: {
            display: true,
            text: 'Ratio (%)',
          },
          callback: (value: number) => `${(value).toFixed(3)}`,
          stepSize: tickSpacing,
          suggestedMin,
          suggestedMax,
        },
      },
    };
  } if (chartType === 'bar') {
    const nonNullValues = combinedArray?.filter((value: number) => value !== null);
    const maxValue = Math.max(...nonNullValues);
    const yAxisTitle = checkMaximumValueUnit(maxValue);
    return {
      ...commonOptions,
      scales: {
        ...commonOptions.scales,
        y: {
          title: {
            display: true,
            text: `${yAxisTitle} ($)`,
          },
        },
      },
    };
  }

  return commonOptions;
};

export default options;
