import React from 'react';
import { DataItem } from '../../../shared/components/Export/index.type';
import { formatChartLabel, formatMonthYear } from '../../../utils/utils';
import { StyledDataChart } from '../../../pages/PeerAnalytics/index.styles';
import { formatChartData } from '../../../pages/PeerAnalytics/ChartData';
import Chart from '../../../shared/components/Chart';

const GraphView = ({ content }: {content: DataItem}) => {
  const firstKey = Object.keys(content)[0];
  const reportedDates = content[firstKey]?.map((item: DataItem) => item["Reported Date"]);
  const formattedDate = reportedDates?.map((dateStr: string) => {
    return formatMonthYear(dateStr, "en-US");
  });
  const formattedChartData = formatChartData(content);
  return (
    <StyledDataChart>
      {Object.entries(formattedChartData).map(([key, value]) => (
        <div key={key} className="chart-container">
          <div className="peer-label-name">{formatChartLabel(key)}</div>
          {key.includes('(%)') ? (
            <Chart chartType="line" data={value} label={formattedDate} />
          ) : (
            <Chart chartType="bar" data={value} label={formattedDate} tabName="metrics" />
          )}
        </div>
      ))}
    </StyledDataChart>
  );
};

export default GraphView;
