import React, { useEffect, useRef, useState } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { StyledHeader, NavLeft, NavRight } from './index.styles';
import { CgClose, GiHamburgerMenu, MdOutlineArrowDropDown } from '../../utils/icons';
import Button from '../../shared/components/Button';
import { RootState } from '../../store';
import { convertCamelCaseToUpperCase } from '../../utils/utils';
import { AUTHURL, DOMAIN } from '../../utils/constants';

interface MobileMenuProps {
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
  logOut: () => void;
}
interface EventProps {
  target: HTMLDivElement | any;
}

const MobileMenu = ({
  setMenu, logOut,
}: MobileMenuProps) => {
  return (
    <div className="mobile-globalHeader">
      <div className="menu">
        <div className="mobilemenu-title">
          Menu
          <CgClose
            className="close"
            onClick={() => {
              setMenu(false);
            }}
          />
        </div>
        <Button
          type="button"
          className="profile-dropdown-mobileview"
          onClick={() => { window.location.href = `${AUTHURL}/change-password`; }}
          name="Change Password"
        />
        <Button
          type="button"
          className="profile-dropdown-mobileview"
          onClick={() => logOut()}
          name="Sign Out"
        />
      </div>
    </div>
  );
};

const Header = () => {
  const [authContainer, setAuthContainer] = useState(false);
  const authOptionsRef = useRef<HTMLDivElement>(null);
  const [menu, setMenu] = useState(false);
  const { user } = useSelector((state: RootState) => state.userActions);
  const [cookies, , removeCookie] = useCookies(['accessToken']);

  const userName = `${convertCamelCaseToUpperCase(user.firstName)} ${convertCamelCaseToUpperCase(user.lastName)}`;

  const logOut = () => {
    localStorage.removeItem('token');
    if (cookies.accessToken) removeCookie('accessToken', { path: '/', domain: `.${DOMAIN}` });
    window.location.href = '/login';
  };

  useEffect(() => {
    document.addEventListener("mouseover", (event: EventProps) => {
      if (!authOptionsRef.current?.contains(event.target)) {
        setAuthContainer(false);
      }
    });
  }, [authOptionsRef]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        logOut();
      }
    }
  }, []);

  return (
    <StyledHeader>
      <NavLeft>
        <GiHamburgerMenu
          className="hamburger"
          onClick={() => {
            setMenu(!menu);
          }}
        />
        {menu && (
        <MobileMenu
          logOut={logOut}
          setMenu={setMenu}
        />
        )}
        <div>
          <div
            role="button"
            onKeyDown={undefined}
            onClick={() => { window.location.href = AUTHURL || '/'; }}
            tabIndex={0}
            className="header-container"
          >
            <div className="header-title">
              DataHub
            </div>
          </div>
        </div>
      </NavLeft>
      <NavRight>
        <div
          className="auth"
          onMouseEnter={() => setAuthContainer(!authContainer)}
          ref={authOptionsRef}
        >
          <div className="auth-name">
            {userName}
            <MdOutlineArrowDropDown />
            {authContainer && (
            <div className="auth-container">
              <Button
                type="button"
                className="profile-dropdown"
                onClick={() => { window.location.href = `${AUTHURL}/change-password`; }}
                name="Change Password"
              />
              <Button
                type="button"
                className="profile-dropdown"
                onClick={() => logOut()}
                name="Sign Out"
              />
            </div>
            )}
          </div>
        </div>
      </NavRight>
      <div className="mobile" />
    </StyledHeader>
  );
};

export default Header;
