import React from 'react';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableRowDetail,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import {
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import { StyledGridContainer, StyledGridVerticalTableContainer } from './index.styles';
import {
  GridHorizontalTableProps, GridMultiLevelTableProps, GridVerticalTableProps,
} from './index.type';
import {
  SORTUP, SORTUPACTIVE, SORTDOWNACTIVE, SORTDOWN,
} from '../../../utils/Images';

const SortingIcon = ({ direction }: { direction: string }) => (
  <div className="icon-container">
    <img src={direction === "asc" ? SORTUPACTIVE : SORTUP} alt="sort" className="icon" />
    <img src={direction === "desc" ? SORTDOWNACTIVE : SORTDOWN} alt="sort" className="icon" />
  </div>
);

const SortLabel: React.FC<TableHeaderRow.SortLabelProps|any> = (
  { onSort, children, direction },
) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={undefined}
    onClick={onSort}
    style={{ cursor: "pointer" }}
    className="label-container"
  >
    {children}
    <SortingIcon direction={direction} />
  </div>
);

const GridWithVerticalTable = ({ rowData, columnData }: GridVerticalTableProps) => {
  return (
    <StyledGridVerticalTableContainer>
      <Grid rows={rowData} columns={columnData}>
        <VirtualTable height="auto" />
      </Grid>
    </StyledGridVerticalTableContainer>
  );
};

const GridWithHorizontalTable = ({
  key, rowData, columnData,
}: GridHorizontalTableProps) => {
  return (
    <StyledGridContainer>
      <Grid
        key={key}
        rows={rowData}
        columns={columnData}
      >
        <SortingState />
        <IntegratedSorting />
        <VirtualTable />
        <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />
      </Grid>
    </StyledGridContainer>
  );
};

const GridWithMultiLevelTable = ({
  rowData, columnData, isMetricColumn, RowDetail, handleRenderToggle,
}: GridMultiLevelTableProps) => {
  return (
    <StyledGridContainer>
      <Grid rows={rowData} columns={columnData}>
        <RowDetailState defaultExpandedRowIds={[]} />
        <SortingState />
        <IntegratedSorting />
        <Table />
        <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />
        { isMetricColumn && (
        <TableRowDetail
          contentComponent={RowDetail}
          toggleCellComponent={(props) => {
            return handleRenderToggle({ ...props });
          }}
        />
        )}
      </Grid>
    </StyledGridContainer>
  );
};

export { GridWithVerticalTable, GridWithMultiLevelTable, GridWithHorizontalTable };
