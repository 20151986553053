import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import { StyledDataListing } from './index.styles';
import DataListingsTile from '../../components/DataListingsTile';
import Search from '../../components/Search';
import { RootState } from '../../store';
import Loader from '../../shared/components/Loader';
import Filters from '../../components/Filters';
import Sort from '../../components/Sort';
import Pagination from '../../components/Pagination';
import { fetchAsyncDataListings, dataListingsActions } from '../../store/dataListings';
import { StyledTitle } from '../../shared/components/title';
import Button from '../../shared/components/Button';
import { BreakPoints } from '../../utils/theme';
import Count from './count';
import { DataItem } from '../../shared/components/Export/index.type';
import { SelectedProps } from '../../components/DataCard';
import Breadcrumb from '../../components/Breadcrumb';
import { convertCamelCaseToUpperCase, convertKeyValuePairFormat } from '../../utils/utils';
import FilterChips from '../../components/FilterChips';
import { setSelectedFilters } from '../../store/filter';

interface NavigationItem {
  displayName: string;
  url?: string;
  onClick?: () => void;
}

const getNavigationData = (
  datasetName: string,
  datasetId: string,
  searchTerm: string | null | undefined,
  dispatch: Dispatch<AnyAction>,
): NavigationItem[] => [
  {
    displayName: convertCamelCaseToUpperCase(datasetName),
    url: `/`,
    onClick: () => dispatch(dataListingsActions.Search("")),
  },
  ...(searchTerm ? [{ displayName: searchTerm }] : []),
];

const DataListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = window.screen.width < parseInt(BreakPoints.SmallDevices, 10);
  const [modal, setModal] = useState(false);

  const {
    loading: userLoading,
    currentDataset: { id: datasetId, name: datasetName },
  } = useSelector((state: RootState) => state.userActions);

  const { loading, apiPayload, dataListings } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );

  const { count, rows: dataList } = dataListings;
  const { offset, limit } = apiPayload;

  const searchTerm = apiPayload?.search_key;

  useEffect(() => {
    if (datasetId) {
      dispatch(fetchAsyncDataListings({ endpoint: `data/${datasetId}`, apiPayload }));
    }
    if (searchTerm) {
      navigate(`?searchQuery=${searchTerm}`);
    }
  }, [apiPayload, datasetId]);

  useEffect(() => {
    localStorage.removeItem('activeTab');
  }, []);

  const initialSelectedCards = JSON.parse(localStorage.getItem('selectedCards') ?? '[]');
  const initialSelectedCardObjects = JSON.parse(localStorage.getItem('selectedCardObjects') ?? '[]');
  const [selectedCards, setSelectedCards] = useState<string[]>(initialSelectedCards);
  const [selectedCardObjects, setSelectedCardObjects] = useState<DataItem[]>(
    initialSelectedCardObjects,
  );
  const [appliedFilters, setAppliedFilters] = useState<DataItem[]>([]);
  const [selectedFilterChips, setSelectedFilterChips] = useState<DataItem[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemoveCard = (cardId: string) => {
    const updatedSelectedCardObjects = selectedCardObjects.filter(
      (card: DataItem) => card.cardId !== cardId,
    );
    const updatedSelectedCards = selectedCards.filter((id) => id !== cardId);
    setSelectedCardObjects(updatedSelectedCardObjects);
    setSelectedCards(updatedSelectedCards);
    localStorage.setItem('selectedCards', JSON.stringify(updatedSelectedCards));
    localStorage.setItem('selectedCardObjects', JSON.stringify(updatedSelectedCardObjects));
  };
  const handleAppliedFilter = (filters: DataItem) => {
    setSelectedFilterChips(convertKeyValuePairFormat(filters));
  };

  const handleCardSelect = ({
    id,
    name,
    locations,
    type,
  }: SelectedProps) => {
    const cardObject = {
      cardId: id,
      name,
      locations,
      type,
    };
    if (selectedCards?.length < 3) {
      setSelectedCards([...selectedCards, id]);
      setSelectedCardObjects([...selectedCardObjects, cardObject]);
      localStorage.setItem('selectedCards', JSON.stringify([...selectedCards, id]));
      localStorage.setItem('selectedCardObjects', JSON.stringify([...selectedCardObjects, cardObject]));
    }
  };
  const handleRemoveFilter = (filter: string) => {
    const { filters } = apiPayload;
    const updatedFilter = selectedFilterChips?.filter(({ value }: DataItem) => value !== filter);
    setSelectedFilterChips(updatedFilter);
    const filteredObject = Object.entries(filters).reduce((acc: DataItem, [key, value]) => {
      if (Array.isArray(value) && value.includes(filter)) {
        acc[key] = value.filter((item) => item !== filter);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    dispatch(dataListingsActions.filter(filteredObject));
    Object.keys(filteredObject).forEach((key) => {
      const selectedItems = filteredObject[key];
      dispatch(setSelectedFilters({ name: key, selectedItems }));
    });
  };
  useEffect(() => {
    if (selectedCards?.length > 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [selectedCards]);

  useEffect(() => {
    const storedSelectedFilters = JSON.parse(localStorage.getItem('selectedFilterChips') ?? '[]');
    setSelectedFilterChips(storedSelectedFilters);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedFilterChips', JSON.stringify(selectedFilterChips));
  }, [selectedFilterChips]);

  useEffect(() => {
    const storedAppliedFilters = JSON.parse(localStorage.getItem('appliedFilters') ?? '[]');
    setAppliedFilters(storedAppliedFilters);
  }, []);
  useEffect(() => {
    localStorage.setItem('appliedFilters', JSON.stringify(appliedFilters));
  }, [appliedFilters]);

  return (
    <StyledDataListing>
      {userLoading && <Loader loading={loading} />}
      {!userLoading && (
        <Container>
          <Row>
            <div className="breadcrumb-container">
              <Breadcrumb navigation={getNavigationData(
                datasetName,
                datasetId,
                searchTerm,
                dispatch,
              )}
              />
            </div>
          </Row>
          <Row>
            <Count
              datasetName={datasetName}
              count={count}
              offset={apiPayload.offset}
              limit={apiPayload.limit}
              searchTerm={searchTerm}
              dataList={dataList}
            />
          </Row>
          <Row>
            {!isMobile ? (
              <Col md={3}>
                <StyledTitle>FILTERS</StyledTitle>
              </Col>
            ) : (
              <Button
                type="submit"
                className="primary filter-button"
                onClick={() => setModal(!modal)}
                name="Filters"
              />
            )}
            <Col md={9} className="d-flex justify-content-between col-direction" data-testid="search">
              <Search />
              <Sort />
            </Col>
          </Row>
          <Row className="border-top-grey mt-10">
            <Col md={3} className="border-right-grey">
              <Filters
                disableOnCompare={selectedCardObjects.length > 0 ? "type" : ""}
                setModal={setModal}
                modal={modal}
                isMobile={isMobile}
                handleAppliedFilter={handleAppliedFilter}
              />
            </Col>
            <Col md={9}>
              <Container style={{ padding: '0' }}>
                <FilterChips
                  isModalOpen={isModalOpen}
                  filters={selectedFilterChips}
                  onRemoveFilter={handleRemoveFilter}
                  setAppliedFilters={setAppliedFilters}
                  setSelectedFilterChips={setSelectedFilterChips}
                  isCompareClicked={selectedCards.length > 0}
                />
                {loading ? <Loader loading={loading} /> : (
                  <DataListingsTile
                    isModalOpen={isModalOpen}
                    selectedCards={selectedCards}
                    handleCardSelect={handleCardSelect}
                    selectedCardObjects={selectedCardObjects}
                    handleRemoveCard={handleRemoveCard}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    setSelectedFilterChips={setSelectedFilterChips}
                    setSelectedCards={setSelectedCards}
                    setSelectedCardObjects={setSelectedCardObjects}
                  />
                )}
                { count > 9 && (
                  <Row className="container">
                    <Pagination
                      offset={offset}
                      limit={limit}
                      count={count}
                      action={dataListingsActions}
                    />
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </StyledDataListing>
  );
};

export default DataListing;
