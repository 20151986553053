import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/theme";

const StyledDataChart = styled.div`
    margin: 1% 1%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
    gap: 60px;
    .chart-container {
        width: 45%;
        height: 60%;
    }
    .peer-label-name {
        font-size: 16px;
        font-family:${FontFamily};
        color: ${Colors.darkGrey};
        text-align: center;
        margin-bottom: 15px;
        font-weight: bold;
    }
    .canvas {
        height: 424px;
        width: 400px;
    }
`;

const StyledPeerContainer = styled.div`
    min-height: 100vh;
    margin: 1%;
    font-size: 14px;
    font-family: ${FontFamily.sourceSansPro};
`;

const StyledChartTitle = styled.div`
   font-family: ${FontFamily} ;
   font-size: 25px;
   margin-bottom: 25px;
   color: ${Colors.darkOrange};
   font-weight: 600;
   text-align: center;
`;

export { StyledDataChart, StyledPeerContainer, StyledChartTitle };
