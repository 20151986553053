import styled from "styled-components";
import { BreakPoints, Colors } from "../../../utils/theme";
import { FlexColumnDiv, FlexRowDiv } from "../../../utils/ReusableStyles";

const StyledSection = styled.div`
    margin-bottom: 35px;
    border-radius: 10px;
    background-color: ${Colors.tile};
    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 6px 0.5px;`;

const StyledSubTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: ${Colors.blueBackground};
    margin-bottom: 20px;
`;

const StyledServices = styled(FlexRowDiv)`
    align-items: start;
    justify-content: start;
    margin: 1% 4%;
    gap: 5%;
    .list {
        list-style-type: disc;
    }
    .list-item {
        margin-bottom: 10px;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
        flex-direction: column;
    }
`;

const StyledExportContainer = styled(FlexColumnDiv)`
    margin: 1% 4%;
    align-items: end;
    @media (max-width: ${BreakPoints.SmallDevices}) {
        margin: 2%;
    }
`;

export {
  StyledServices, StyledSection, StyledSubTitle, StyledExportContainer,
};
