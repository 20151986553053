import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../api/dataApi";
import { ProfileInterface } from "./index.type";

export const fetchAsyncProfile: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("profile/fetchAsyncProfile", get);

const initialState : ProfileInterface = {
  profile: {
    Name: "",
    Type: "Bank",
    "RSSD ID": "663245",
    Website: "",
    Address: "",
    Branches: "252",
    "Reported Date": "23-02-2023",
    "Incorporation Date": "null",
    "Credit Card Institution": false,
    "Total Assets": "$27.41M",
    "Total Deposits": "$22.68M",
    "Return On Average Assets": "",
    "Total Domestic Deposits": "$22.68M",
    "Equity Capital": "$3.43M",
    "Net Income": "$0.29M",
    "Net Income Quarterly": "$92173",
    "Return On Assets": "1.11033%",
    "Pretax ROA": "1.33834%",
    "Quarterly Pretax ROA": "1.55000%",
    "Quarterly ROA": "8.64%",
    "Return On Equity": "10.85%",
    "Quarterly ROE": "1.35%",
  },
  loading: false,
  error: {},
};
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchAsyncProfile.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncProfile.fulfilled]: (state, { payload }) => {
      return { ...state, profile: payload, loading: false };
    },
    [fetchAsyncProfile.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
