import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import ChartData from '../../../pages/PeerAnalytics/ChartData';
import Export from '../Export';
import { GridWithHorizontalTable } from '../Grid';
import { getColumns, getRows } from '../../../components/Detail/Merger';
import { DataItem } from '../Export/index.type';
import StyledNavTab from './index.styles';
import GraphView from '../../../components/Detail/Metrics/GraphView';
import SwitchButton from '../switch';

interface TabItem {
    eventKey: string;
    label: string;
    content: DataItem[];
    graphContent?: DataItem[];
}

interface TabProps {
    tabItems: TabItem[];
    tabName: string;
}

const index = ({
  tabItems, tabName,
}: TabProps) => {
  const [activeTab, setActiveTab] = useState<string>('MACRO PROFIT');
  const [isGraphView, setGraphView] = useState(false);

  const handleTabChange = (tabId: React.SetStateAction<any>) => {
    setActiveTab(tabId);
  };
  return (
    <StyledNavTab>
      <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
        <div className="d-flex">
          <Nav variant="tabs" className="flex-column">
            {tabItems?.map((tabItem) => (
              <Nav.Item key={tabItem?.eventKey}>
                <Nav.Link eventKey={tabItem?.eventKey}>{tabItem?.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {tabItems?.map((tabItem) => (
              <Tab.Pane key={tabItem?.eventKey} eventKey={tabItem?.eventKey}>
                {tabName === 'peer-analytics' ? <ChartData content={tabItem?.content} tabName={tabItem?.eventKey} /> : (
                  <>
                    <div className="toggle-container">
                      <SwitchButton
                        isChecked={isGraphView}
                        onChange={
                    () => setGraphView(!isGraphView)
                    }
                      />
                      <Export
                        rowData={getRows(tabItem?.content)}
                        columnData={getColumns(tabItem?.content)}
                        fileName={tabItem?.eventKey}
                      />
                    </div>
                    {!isGraphView && (
                    <GridWithHorizontalTable
                      key={tabItem?.eventKey}
                      rowData={getRows(tabItem?.content)}
                      columnData={getColumns(tabItem?.content)}
                    />
                    )}
                    {isGraphView && tabItem?.graphContent && (
                    <GraphView content={tabItem?.graphContent} />
                    )}
                  </>
                )}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    </StyledNavTab>
  );
};

export default index;
