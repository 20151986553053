import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import { DataItem } from '../../shared/components/Export/index.type';
import { fetchAsyncMetrics } from '../../store/metrics';
import Breadcrumb from '../../components/Breadcrumb';
import { getNavigation } from '../Detail';
import NavTab from '../../shared/components/NavTab';
import Loader from '../../shared/components/Loader';
import { StyledPeerContainer } from './index.styles';

export const formatDataWithOuterKey = (data: DataItem) => {
  return Object.keys(data).reduce((formattedData: DataItem, innerKey) => {
    const innerObj = data[innerKey];
    Object.keys(innerObj).forEach((outerKey: string) => {
      if (!formattedData[outerKey]) {
        formattedData[outerKey] = {};
      }

      formattedData[outerKey][innerKey] = innerObj[outerKey];
    });

    return formattedData;
  }, {});
};

export const getFilteredData = (rows: DataItem[]) => {
  if (!rows || rows?.length === 0) {
    return { rows: {} };
  }
  const firstrowKeys = Object.keys(rows[0]);
  const filteredData = firstrowKeys
    .filter((key) => key !== "Reported Date" && key !== "id" && key !== "fiId")
    .reduce((result: DataItem, key) => {
      result.rows[key] = rows?.slice(0, 4).map((row) => ({
        'Reported Date': row['Reported Date'],
        ...row[key],
      }));
      return result;
    }, { rows: {} });

  return filteredData;
};

export const formatGraphData = (rows: DataItem, cardIds: string[], state: DataItem[]) => {
  const filteredFiIdArrays = cardIds?.reduce((result: DataItem, fiId: string) => {
    result[fiId] = rows?.filter((item: { fiId: string; }) => item.fiId === fiId);
    return result;
  }, {});
  const processedData: DataItem = {};

  Object.keys(filteredFiIdArrays).forEach((key) => {
    processedData[key] = getFilteredData(filteredFiIdArrays[key]);
  });

  const mappedData = Object.fromEntries(
    Object.entries(processedData)?.map(([key, value]) => [key, { ...value.rows }]),
  );

  const formattedDataWithKey = Object.entries(mappedData).reduce((acc: DataItem, [key, value]) => {
    const matchingCard = state.find((card: DataItem) => card?.cardId === key);
    if (matchingCard) {
      acc[matchingCard.name] = value;
    }
    return acc;
  }, {});
  return formattedDataWithKey;
};

const PeerAnalytics = () => {
  const { metrics, loading } = useSelector(
    (state: RootState) => state.metricsActions,
  );
  const {
    currentDataset: { id: datasetId, name: datasetName },
  } = useSelector((state: RootState) => state.userActions);

  const { state } = useLocation();

  const cardIds = state?.map((item: { cardId: string; }) => item.cardId);
  const apiUrl = cardIds?.join(',');
  const { rows } = metrics;

  const formattedTabData = formatDataWithOuterKey(formatGraphData(rows, cardIds, state));

  const tabItems = Object.keys(formattedTabData)?.map((key) => ({
    eventKey: key.toLocaleUpperCase(),
    label: key.toLocaleUpperCase(),
    content: formattedTabData[key],
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncMetrics(`details/${datasetId}/metrics?ids=${apiUrl}`));
    window.scrollTo(0, 0);
  }, [datasetId]);

  return (
    <StyledPeerContainer>
      {(loading) && <Loader loading={loading} />}
      {!loading && (
      <>
        <Breadcrumb navigation={getNavigation(
          datasetName,
          "Peer-Analytics",
        )}
        />
        <NavTab
          tabItems={tabItems}
          tabName="peer-analytics"
        />
      </>
      )}
    </StyledPeerContainer>
  );
};

export default PeerAnalytics;
