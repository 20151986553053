import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import DataListing from './pages/DataListing';
import Footer from './components/Footer';
import ListingDetail from './pages/Detail';
import PeerAnalytics from './pages/PeerAnalytics';
import withAuthentication from './utils/withAuthentication';
import { RootState } from './store';
import Loader from './shared/components/Loader';

const App = () => {
  const { loading: userLoading } = useSelector(
    (state: RootState) => state.userActions,
  );
  return (
    <>
      {userLoading && <Loader loading={userLoading} />}
      {!userLoading && (
      <>
        <Header />
        <Routes>
          <Route path="/" element={<DataListing />} />
          <Route path="/:id/:name" element={<ListingDetail />} />
          <Route path="/peer-analytics" element={<PeerAnalytics />} />
        </Routes>
        <Footer />
      </>
      )}
    </>
  );
};

export default withAuthentication(App);
