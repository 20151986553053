import React from 'react';
import {
  StyledServices, StyledSection, StyledSubTitle,
  StyledExportContainer,
} from './index.styles';
import { ServiceProps } from './index.type';
import Export from '../../../shared/components/Export';
import { formatTableRows } from '../../../utils/utils';

const index = ({ services } : { services: ServiceProps}) => {
  const columns = [
    { name: 'Key', title: 'Key' },
    {
      name: 'value',
      title: 'value',
    },
  ];
  return (
    <>
      <StyledExportContainer>
        <Export
          rowData={formatTableRows(services)}
          columnData={columns}
          fileName="Services"
        />
      </StyledExportContainer>
      <StyledServices>
        {Object.entries(services)?.map(([title, listItems]) => (
          <StyledSection>
            <StyledSubTitle>{title}</StyledSubTitle>
            <ul className="list">
              {listItems?.map((result: string) => (
                <li className="list-item">{result}</li>
              ))}
            </ul>
          </StyledSection>
        ))}
      </StyledServices>
    </>
  );
};

export default index;
