import React from "react";
import StyledButton from "./index.styles";

interface ButtonProps {
  name: string | React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset" | "button";
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  name,
  onClick,
  type = "submit",
  className,
  disabled,
}) => {
  return (
    <StyledButton type={type} onClick={onClick} className={className} disabled={disabled}>
      {name}
    </StyledButton>
  );
};

Button.defaultProps = {
  type: "submit",
  className: "button",
  onClick: () => {
    return null;
  },
  disabled: false,
};

export default Button;
