import styled from "styled-components";
import { Colors } from "../../utils/theme";
import { FlexRowDiv } from "../../utils/ReusableStyles";

const StyledFooter = styled(FlexRowDiv)`
  height: 13vh;
  width: 100%;
  color: ${Colors.white};
  font-size: 16px;
  background-color: ${Colors.background};
`;

export default StyledFooter;
