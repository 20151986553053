import React from 'react';
import StyledSwitch from './index.styles';
import Button from '../Button';

interface SwitchButtonProps {
    isChecked: boolean;
    onChange: () => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ isChecked, onChange }) => {
  return (
    <StyledSwitch>
      <div className="switch-container">
        <Button
          name="Table"
          onClick={isChecked ? onChange : undefined}
          type="button"
          className={`switch-button ${isChecked ? '' : 'table-active'}`}
          disabled={!isChecked}
        />
        <Button
          name="Graph"
          onClick={isChecked ? undefined : onChange}
          type="button"
          className={`switch-button ${isChecked ? 'graph-active' : ''}`}
          disabled={isChecked}
        />
      </div>
    </StyledSwitch>
  );
};

export default SwitchButton;
