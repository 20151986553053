import styled from "styled-components";
import { FlexColumnDiv, FlexRowDiv } from "../../utils/ReusableStyles";
import { BreakPoints, Colors, FontFamily } from "../../utils/theme";

const StyledDataListing = styled(FlexColumnDiv)`
    min-height: 100vh;
    .col-direction {
        display: none;
        @media (max-width: ${BreakPoints.SmallDevices}) {
            flex-direction: column-reverse;
        }
    }
    .filter-button {
        margin: 12px;
        width: -webkit-fill-available;
    }
    .border-right-grey {
        border-right: solid 1px ${Colors.grey30};
    }
    .border-top-grey {
        border-top: solid 1px ${Colors.grey30};
    }
    .mt-10 {
        margin-top: 10px;
    }
    .breadcrumb-container {
        margin-top: 20px;
        padding-left: 5px;
    }
`;

const StyledCount = styled.div`
    font-size: 1.15rem;
    padding: 0% 5px 3% 5px;
    font-family: ${FontFamily.sourceSansPro};
    color: ${Colors.black};
    .highlight-two {
        color: ${Colors.orange};
        padding-left: 5px;
    }
    .highlight-one {
        font-weight: 600;
        font-size: 18px;
        color: ${Colors.blueSurface};
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
        font-size: 1rem;
    }
`;

const StyledCompareModal = styled(FlexRowDiv)`
    display: flex;
    position: fixed;
    margin: 0% 1% 0% 1%;
    top: 70%;
    right: 1%;
    left: 1%;
    height: fit-content;
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .card-title {
        font-size: 14px;
        color:  ${Colors.blueSurface};
        margin-bottom: 10px;
        width: 80%;
    }
    .title-placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .button-disabled {
        color: ${Colors.black};
        cursor: not-allowed;
        background-color: ${Colors.grey};
        opacity: 0.3;
    }
    .compare-text {
        font-size: 16px;
        color: ${Colors.grey};
        width: 85%;
        font-weight: 600;
    }
    .data-card-placeholder {
        border: 1px dashed ${Colors.grey};
        width: 22vw;
        height: 17vh;
        margin-top: 3%;
    }
    .format-button {
        width: 60%;
        margin: 0px;
        padding: 5px;
    }
    .card-container {
        display: flex;
        align-items: center;
        gap: 60px;
    }
    .view-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .data-card {
        display: grid;
        position: relative;
        grid-template-columns: repeat(3, 1fr);
        margin: 10px;
        gap: 30px;
    }
    .close-button-container {
        position: absolute;
        top: 0px;
        right: -1px;
        z-index: 1000;
    }
    .minmax-icon {
        font-size: 16px;
    }
    @media screen and (max-width: ${BreakPoints.LargeDevices}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: ${BreakPoints.SmallDevices}) {
        grid-template-columns: repeat(1, 1fr);
        margin: 2px;
    }
`;

const StyledModalContent = styled.div`
    display: flex;
    background-color: ${Colors.white};
    padding: 20px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const StyledCloseButton = styled.div`
    color: ${Colors.black};
    font-size: 18px;
    padding: 0px 10px;
    cursor: pointer;
    &:hover {
        background-color: ${Colors.grey};
        color: ${Colors.white};
    }
`;

const StyledDataModalCard = styled(FlexColumnDiv)`
    position: relative;
    align-items: initial;
    cursor: pointer;
    margin: 5px;
    padding: 16px;
    height: 18vh;
    width: 22vw;
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
    border: solid transparent 1px;
    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
`;

const StyledPopup = styled.div`
    .header-container {
        display: flex;
        margin-bottom: 20px;
    }
    .controls-container {
        display: flex;   
    }
    .close-button-container {
        margin-right: 1px;
    }
    .minimize-maximize-container .button {
        color: ${Colors.black};
        font-size: 18px;
        border: none;
        position: absolute;
        top: 0px;
        right: 28px;
        padding: 0px 10px;
        cursor: pointer;
        &:hover {
            background-color: ${Colors.grey};
            color: ${Colors.white};
        }
    }
    .minimized {
        width: 200px;
        height: 30px;
        overflow: hidden;
        transition: all 0.3s ease;
        background-color: ${Colors.white};
    }
    .heading {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 600;
        color:  ${Colors.blueSurface};
        font-family: ${FontFamily.sourceSansPro};
        background-color: ${Colors.white};
        margin-right: 50px;
        gap: 5px;
        align-items: center;
    } 
`;

export {
  StyledDataListing, StyledCount, StyledCompareModal,
  StyledDataModalCard, StyledCloseButton, StyledModalContent,
  StyledPopup,
};
