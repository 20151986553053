import styled from "styled-components";
import { BreakPoints, Colors } from "../../../utils/theme";
import { FlexColumnDiv, FlexRowDiv } from "../../../utils/ReusableStyles";

const StyledNoResultImageContainer = styled(FlexRowDiv)`
    gap: 10px;
    border-radius: 160px;
    background-color: ${Colors.subduedSurface};
`;

const StyledNoResultFound = styled(FlexColumnDiv)`
  padding-top: 200px;
  .noresult-icon {
    width: 184px;
    height: 184px;
    object-fit: contain;
    padding: 40px;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    padding-top: 32px;
  }
`;

export { StyledNoResultFound, StyledNoResultImageContainer };
