import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../../utils/theme";

const StyledNavTab = styled.div`
   .css-xhtqz8-MuiTableCell-root.TableCell-cellNoWrap {
        text-align: center !important;
    }
    .nav {
        background-color: ${Colors.lightGreyBackground};
        width: 15%;
        text-align: start;
        border: none;
        font-weight: 600;
        font-size: 14px;
        @media (max-width: ${BreakPoints.MediumDevices}) {
            width: 30%;
        }
        @media (max-width: ${BreakPoints.LargeDevices}) {
            height: 170vh;
        }
    }
    .nav-tabs .nav-link {
        padding: 18px;
        color: ${Colors.black};
        font-family: ${FontFamily.inherit};
        border-bottom: 1px solid ${Colors.greyBackground};
    }
    .nav-tabs .nav-link.active {
        color: ${Colors.orange};
        border-left: 4px solid ${Colors.orange} ;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom: none;
        border-right: 0px;
        border-right-color: transparent;
    }
    .tab-content {
        width: 85%;
        margin: 1% 0 0 1%;
        @media (max-width: ${BreakPoints.MediumDevices}) {
            width: 70%;
        }
    }
    .toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;
export default StyledNavTab;
