import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/theme";

const StyledBreadCrumbs = styled.div`
    display: flex;
    font-size: 13px;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 20px;
    span {
        color: ${Colors.subduedText};
        padding-left: 4px;
        padding-right: 4px;
    }
    .dotted-crumbs {
        display: none;
    }
    .display-crumbs {
        font-size: 13px;
    }
    .crumbs:last-child {
        .display-crumbs {
            color: ${Colors.subduedText};
            pointer-events: none;
            cursor: default;
        }
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
        margin-top: 20px;
        .dotted-crumbs {
            display: block;
        }
        .display-crumbs {
            display: none;
        }
        .crumbs:last-child {
            width: 100%;
            overflow: hidden;
            .display-crumbs {
                display: block;
                white-space: nowrap;
                overflow: hidden;
            }
            .dotted-crumbs {
                display: none;
            }
        }
    }
`;

export default StyledBreadCrumbs;
