import React from "react";
import { useDispatch } from "react-redux";
import StyledFilterChips from "./index.styles";
import { BsX } from "../../utils/icons";
import { DataItem } from "../../shared/components/Export/index.type";
import { dataListingsActions } from "../../store/dataListings";
import { setSelectedFilters } from "../../store/filter";

interface FilterChipsProps {
  isModalOpen: boolean;
  filters: DataItem[];
  onRemoveFilter: (filter: string) => void;
  setAppliedFilters: (filters: DataItem[]) => void;
  setSelectedFilterChips: (filterchips: DataItem[]) => void;
  isCompareClicked: boolean;
}

const FilterChips = ({
  isModalOpen,
  filters,
  onRemoveFilter,
  setAppliedFilters,
  setSelectedFilterChips,
  isCompareClicked,
}: FilterChipsProps) => {
  const dispatch = useDispatch();
  const resetFilter = () => {
    dispatch(dataListingsActions.filter({}));
    localStorage.removeItem("selectedFilterChips");
    localStorage.removeItem("appliedFilters");
    setAppliedFilters([]);
    ["type", "asset_group", "region", "deposit_group"].forEach((label) => {
      dispatch(setSelectedFilters({ name: label, selectedItems: [] }));
    });
    setSelectedFilterChips([]);
  };

  return (
    <StyledFilterChips>
      <div className="StyledFilter">
        <ul className="filter-chip-list">
          {filters?.map((filter: DataItem) => (
            <li key={filter?.value}>
              <div className="filter-chip">
                {`${filter?.key}: ${filter?.value}`}
                <BsX
                  className="remove-icon"
                  onClick={() => onRemoveFilter(filter?.value)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="button">
        <button
          type="button"
          className={isModalOpen || filters.length === 0 ? "disabled" : "reset"}
          onClick={
            isCompareClicked || filters.length === 0 ? undefined : resetFilter
          }
          disabled={isCompareClicked}
        >
          Reset
        </button>
      </div>
    </StyledFilterChips>
  );
};

export default FilterChips;
