import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import StyledFilters from './index.styles';
import { MultiFilter, RangeFilter } from './filters';
import { Colors } from '../../utils/theme';
import { RootState } from '../../store';
import ModalComponent from '../../shared/components/Modal/Modal';
import Button from '../../shared/components/Button';
import { dataListingsActions } from '../../store/dataListings';

interface FiltersProps {
  isMobile?: boolean,
  setModal?: (val: boolean) => void
  modal?: boolean,
  disableOnCompare: string,
  handleAppliedFilter?: (appliedFilters: Record<string, unknown>) => void;
}

const FilterMenu = ({
  isMobile, setModal, handleAppliedFilter, disableOnCompare,
}: Partial<FiltersProps>) => {
  const {
    currentDataset: ds,
  } = useSelector((state: RootState) => state.userActions);

  const options = ds && ds.filters;

  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const applyFilter = (appliedFilters: Record<string, unknown>) => {
    if (!isMobile
      && !_.isEmpty(appliedFilters)) dispatch(dataListingsActions.filter(appliedFilters));
    setFilters({ ...filters, ...appliedFilters });
    handleAppliedFilter?.(appliedFilters);
  };
  const debouncedOnClick = _.debounce(() => {
    if (!_.isEmpty(filters)) dispatch(dataListingsActions.filter(filters));
    if (setModal) setModal(false);
  }, 100);
  return (
    options
      ? (
        <StyledFilters alignItems="start" className={!isMobile ? "hide-mobile" : ""}>
          {options.map(({
            name, type, defaultExpand, column, ...filter
          }) => (
            <Accordion
              className="mui-accordion"
              defaultExpanded={defaultExpand}
              disableGutters
              key={name}
            >
              <AccordionSummary
                expandIcon={<ExpandMore style={{ color: Colors.orange }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{name}</Typography>
              </AccordionSummary>
              {(type === 'RANGE' && filter.min !== undefined && filter.max)
           && (
           <AccordionDetails>
             <RangeFilter
               name={column}
               min={filter.min}
               max={filter.max}
               applyFilter={applyFilter}
             />
           </AccordionDetails>
           )}
              {(type === 'MULTI' && filter.options !== undefined)
           && (
           <MultiFilter
             disableOnCompare={disableOnCompare || ""}
             name={column}
             options={filter.options}
             applyFilter={applyFilter}
           />
           )}
            </Accordion>
          ))}
          {isMobile && (
          <div className="d-flex  w-100">
            <Button
              type="submit"
              className="secondary align-self-center  w-100"
              onClick={() => { if (setModal) setModal(false); }}
              name="Cancel"
            />
            <Button
              type="submit"
              className="primary align-self-center  w-100"
              onClick={debouncedOnClick}
              name="Apply Filters"
            />
          </div>
          )}
        </StyledFilters>
      ) : <div />);
};

FilterMenu.defaultProps = {
  isMobile: false,
  setModal: () => null,
  modal: false,
};

const Filters = ({
  setModal, modal, isMobile, handleAppliedFilter, disableOnCompare,
}: FiltersProps) => {
  return (
    isMobile
      ? (
        <div>
          {modal && (
          <ModalComponent isOpen={modal} modalHeight="100%" modalWidth="100%">
            <FilterMenu
              disableOnCompare={disableOnCompare}
              setModal={setModal}
              isMobile={isMobile}
              handleAppliedFilter={handleAppliedFilter}
            />
          </ModalComponent>
          )}
        </div>
      ) : (
        <FilterMenu
          disableOnCompare={disableOnCompare}
          handleAppliedFilter={handleAppliedFilter}
        />
      )
  );
};

Filters.defaultProps = {
  isMobile: false,
  setModal: () => null,
  modal: false,
  handleAppliedFilter: () => null,
};

export default Filters;
