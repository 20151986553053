import styled from "styled-components";
import { Colors, FontFamily } from "../../../utils/theme";
import { FlexColumnDiv } from "../../../utils/ReusableStyles";

const StyledGridContainer = styled.div`
    width: 100%;
    border: 0.01px solid ${Colors.tableBorder};
    .toggle-cell {
        display: flex;
        align-items: center;
        position: relative;
    }
    .toggle-cell.disabled {
        .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
            pointer-events: none;
        }
    }
    .css-7wtp12-MuiTableCell-root {
        padding: 4px !important;
        border: 1px solid transparent;
        border-top-color: ${Colors.tableBorder};
    }
    .css-1ecsaki-MuiTable-root.Table-table {
        table-layout: auto !important;
    }
    .icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0px;
    }
    .icon {
        width: 6px;
        height: 6px;
        object-fit: contain;
    }
    .label-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    .css-gxs2o2.Content-content {
        justify-content: center !important;
    }
    .css-xhtqz8-MuiTableCell-root.TableCell-cellNoWrap {
        white-space: initial;
        text-align: left;
    }
    th {
        background-color: ${Colors.background};
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        height: 20px;
        color: ${Colors.white};
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1o6p3b3-MuiTableCell-root.CellLayout-cell:first-of-type {
        padding-left: 4px !important;
    }
    tbody, td, tfoot, th, thead, tr {
        border-color: ${Colors.tableBorder};
        border-style: solid;
        border-width: 0.1px; 
    }
    .css-1o6p3b3-MuiTableCell-root {
        padding: 10px;
    }
    .css-xhtqz8-MuiTableCell-root {
        padding: 10px;
    }
    td {
        color: ${Colors.darkSurface};
        font-family: ${FontFamily.sourceSansPro};
    }
    .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
        opacity: 10;
        color: ${Colors.white} !important;
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1mrv5kl.Title-title {
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
        opacity: 10;
        color: ${Colors.white} !important;
        &:hover {
            color: ${Colors.orange};
        }
    }
    .Title-title css-1mrv5kl {
        color: ${Colors.white};
    }
    .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
        color: ${Colors.white};
        &:hover {
            color: ${Colors.orange};
        }
    }
    .css-1o6p3b3-MuiTableCell-root {
        color: ${Colors.white};
    }
    .css-oyg2rb.Table-table {
        table-layout: auto;
        border-collapse: separate;
    }
    .css-1kqseyz.TableDetailToggleCell-toggleCell {
        border: 1px solid transparent;
        border-top-color: ${Colors.tableBorder};
    }
`;

const StyledGridVerticalTableContainer = styled(FlexColumnDiv)`
    align-items: initial;
    .css-xhtqz8-MuiTableCell-root.TableCell-cell:first-of-type {
        font-weight: bold;
        font-size: 14px;
        font-weight: 600;
        color: ${Colors.darkSurface};
        font-family: ${FontFamily.sourceSansPro};
    }
    .primary {
        width: 20%;
    }
    tbody, td, tfoot, th, thead, tr {
        border-color: ${Colors.tableBorder};
        border-style: solid;
        border-width: 0.2px;
    }
    .css-1o6p3b3-MuiTableCell-root {
        padding: 10px;
    }
    .css-xhtqz8-MuiTableCell-root {
        padding: 10px;
    }
    td {
        color: ${Colors.darkSurface};
        font-family: ${FontFamily.sourceSansPro};
    }
    .css-1ecsaki-MuiTable-root.Table-table {
        table-layout: auto !important;
    }  
`;

export {
  StyledGridVerticalTableContainer,
  StyledGridContainer,
};
