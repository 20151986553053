import React from 'react';
import { generateChartColor } from '../../../utils/utils';
import { DataItem } from '../Export/index.type';
import StyledChartLabel from './index.styles';

const ChartLabel = ({ data } : { data: DataItem}) => {
  return (
    <StyledChartLabel>
      {data?.map((value: string, index: number) => {
        const { borderColor, backgroundColor } = generateChartColor(index);

        return (
          <div className="label-item">
            <span
              className="label"
              style={{ backgroundColor, border: borderColor }}
            />
            {value}
          </div>
        );
      })}
    </StyledChartLabel>
  );
};

export default ChartLabel;
