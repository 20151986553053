import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Draggable from 'react-draggable';
import { FaAngleDown, FaAngleUp } from "../../utils/icons";
import { CompareModalProps, LocationType } from "../../components/DataListingsTile/DataListingType";
import { StyledDataContent } from "../../components/DataCard/index.styles";
import Button from "../../shared/components/Button";
import { DataItem } from "../../shared/components/Export/index.type";
import {
  StyledCloseButton, StyledCompareModal, StyledDataModalCard, StyledModalContent, StyledPopup,
} from "./index.styles";
import { dataListingsActions } from "../../store/dataListings";
import { RootState } from "../../store";
import { setSelectedFilters } from "../../store/filter";
import { setSortOption } from "../../store/sort";
import { convertKeyValuePairFormat } from "../../utils/utils";

function getLocationDetails(locations: LocationType) {
  const locationData = [
    locations?.city?.name || locations?.county?.name,
    locations?.state?.abbrevation,
    locations?.zip,
    locations?.country,
  ].filter(Boolean);
  return (
    <div>
      {locationData?.join(', ')}
    </div>
  );
}

const renderCardPlaceholders = (
  selectedObjects: DataItem[],
  placeholderKeyCounter: { current: number },
) => {
  const placeholders = Math.max(0, 3 - (selectedObjects?.length || 0));
  return Array.from({ length: placeholders }, (_, index) => {
    const currentCounter = placeholderKeyCounter.current + index;
    return (
      <div
        className="data-card-placeholder"
        key={`placeholder-${currentCounter}`}
      />
    );
  });
};

const CompareModal = ({
  selectedObjects,
  setSelectedCards,
  setSelectedCardObjects,
  onRemoveCard,
  setAppliedFilters,
  setSelectedFilterChips,
  setParsedCompareData,
}: CompareModalProps) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );
  const placeholderKeyCounter = useRef(0);
  const type = selectedObjects[0]?.type;
  const cardIds = selectedObjects?.map((item) => item.cardId).join(',');

  const handleCloseCardContainer = () => {
    const { filters } = apiPayload;
    const removedFilters = {
      ...filters,
    };
    dispatch(dataListingsActions.filter(removedFilters));
    setSelectedCards([]);
    setSelectedCardObjects([]);
    setAppliedFilters([]);
    setSelectedFilterChips(convertKeyValuePairFormat(removedFilters));
    localStorage.removeItem("selectedCards");
    localStorage.removeItem("selectedCardObjects");
    localStorage.removeItem('selectedCompareData');
    localStorage.removeItem('selectedFilterChips');
  };
  const handleRemoveCard = (card: DataItem) => {
    onRemoveCard(card?.cardId);
    setParsedCompareData((prevData: DataItem[]) => {
      const updatedData = prevData?.filter((data: DataItem) => data.id !== card?.cardId);
      localStorage.setItem("selectedCompareData", JSON.stringify(updatedData));
      return updatedData;
    });
    if (selectedObjects?.length === 1) {
      const { filters } = apiPayload;
      const removedFilters = {
        ...filters,
        type: [],
        asset_group: [],
      };
      dispatch(dataListingsActions.filter(removedFilters));
      setSelectedFilterChips(convertKeyValuePairFormat(removedFilters));
      setAppliedFilters([]);
      ['type', 'asset_group'].forEach((label) => {
        dispatch(setSelectedFilters({ name: label, selectedItems: [] }));
      });
      dispatch(dataListingsActions.Sort([
        "property",
        "total_assets",
        "DESC NULLS LAST",
      ]));
      dispatch(setSortOption("Highest Asset"));
    }
  };
  const handleMinimize = () => {
    setIsMinimized(true);
  };

  const handleMaximize = () => {
    setIsMinimized(false);
  };

  return (
    <StyledPopup>
      <Draggable handle=".draggable-modal" cancel=".no-drag">
        <StyledCompareModal className={`draggable-modal ${isMinimized ? 'minimized' : ''}`}>
          <div className="header-container">
            <div className="controls-container">
              <div className="close-button-container no-drag">
                <StyledCloseButton onClick={handleCloseCardContainer}>x</StyledCloseButton>
              </div>
              <div className="minimize-maximize-container button no-drag">
                <div tabIndex={0} className="button" onKeyDown={undefined} role="button" onClick={isMinimized ? handleMaximize : handleMinimize}>
                  {isMinimized ? <FaAngleUp className="minmax-icon" /> : <FaAngleDown className="minmax-icon" />}
                </div>
              </div>
            </div>
          </div>
          { isMinimized && (
            <div className="heading">
              <div className="analytics-text">Peer Analytics</div>
              <div className="selected-length">
                {`(${selectedObjects?.length})`}
              </div>
            </div>
          )}
          { !isMinimized && (
          <StyledModalContent>
            <div className="card-container">
              <div className="data-card">
                {selectedObjects?.map((card: DataItem) => (
                  <StyledDataModalCard className="no-drag">
                    <div className="close-button-container">
                      <StyledCloseButton
                        onClick={() => handleRemoveCard(card)}
                      >
                        x
                      </StyledCloseButton>
                    </div>
                    <div className="card-title ">
                      {card?.name}
                    </div>
                    <StyledDataContent>
                      <div>
                        {card?.locations?.addressLineOne}
                        <br />
                        {card?.locations?.addressLineTwo}
                      </div>
                      {getLocationDetails(card?.locations)}
                    </StyledDataContent>
                  </StyledDataModalCard>
                ))}
                {renderCardPlaceholders(selectedObjects, placeholderKeyCounter)}
              </div>
              <div className="view-container">
                <div className="compare-text">
                  Select upto 3
                  {' '}
                  {type}
                  {' '}
                  to view Peer Analytics
                </div>
                <Button
                  type="submit"
                  className={`primary no-drag format-button ${selectedObjects?.length === 1 && 'button-disabled'}`}
                  name="View"
                  onClick={() => { navigate(`/peer-analytics?ids=${cardIds}`, { state: selectedObjects }); }}
                  disabled={selectedObjects?.length === 1}
                />
              </div>
              {' '}
            </div>
          </StyledModalContent>
          )}
        </StyledCompareModal>
      </Draggable>
    </StyledPopup>
  );
};

export default CompareModal;
