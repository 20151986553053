import styled from "styled-components";
import { Colors, FontFamily } from "../../../utils/theme";

interface ButtonProps {
    padding?: string;
    borderRadius?: string;
    margin?: string;
}

const StyledButton = styled.button<ButtonProps>`
        border: none;
        font-family: ${FontFamily.sourceSansPro};
        cursor: pointer;
        color: ${Colors.white01};
        background-color: transparent;
        &.primary {
            font-size: 16px;
            padding: ${(props) => props.padding || '10px'};
            border-radius: ${(props) => props.borderRadius || '3px'};
            margin: ${(props) => props.margin || '20px'};
            background-color: ${Colors.orange};
            width: 100%;
        }
        &.secondary {
            font-size: 16px;
            color: ${Colors.orange};
            padding: ${(props) => props.padding || '10px'};
            border-radius: ${(props) => props.borderRadius || '3px'};
            margin: ${(props) => props.margin || '20px'};
            border: 1px solid ${Colors.orange};
            background-color: ${Colors.white};
            width: 100%;
        }
`;

export default StyledButton;
