import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import {
  StyledSearchBarContainer,
  StyledSearch, StyledSearchMoreResultsContainer, StyledSearchContent, StyledSearchContainer,
} from './index.styles';
import { dataListingsActions } from '../../store/dataListings';
import * as dataAPIService from '../../api/dataApi';
import {
  EventProps, KeyCodeProps, SearchDropDownProps, searchResultProps,
} from './SearchType';
import { formatUrl } from '../../utils/utils';
import { LocationType } from "../DataListingsTile/DataListingType";
import { RootState } from '../../store';

const formatLocation = ({
  addressLineOne, city, county, state, zip,
}: LocationType) => {
  return `${addressLineOne && `, ${addressLineOne}`}
  ${city.name ? `, ${city?.name}` : `, ${county?.name}`}
  ${state?.abbrevation && `, ${state?.abbrevation}`}
  ${zip && `, ${zip}`}`;
};

export const SearchDropDown = ({
  search,
  dataList,
  setData,
  isClicked,
  handleKeyDown,
  selectedIndex,
  setSearchTerm,
  searchTerm,
  dataLoading,
}: SearchDropDownProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const noResult = search.length > 2 && dataList?.length === 0 && !isClicked && !dataLoading;
  const isResults = dataList && dataList?.length > 0 && search.length > 2 && !dataLoading;
  const isPagination = isResults && dataList?.length > 4;

  const renderResults = () => {
    if (noResult) {
      return (<div className="no-result">No results found</div>);
    }
    if (isResults) {
      return dataList.map(({ id, name, locations } : searchResultProps, index: number) => (
        <StyledSearchContent className={`${index === selectedIndex ? 'selected-result' : ''}`}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              dispatch(dataListingsActions.Search(searchTerm));
              navigate(`/${id}/${formatUrl(name)}`, { state: [{ cardId: id, name }] });
            }}
            onKeyDown={undefined}
            className="link-text"
          >
            <div>
              <span className="name-text">
                {name?.toUpperCase()}
              </span>
              <span className="location-text">
                {locations ? formatLocation(locations) : ''}
              </span>
            </div>
          </div>
        </StyledSearchContent>
      ));
    }
    return null;
  };
  return (
    <StyledSearchContainer onKeyDown={handleKeyDown}>
      {renderResults()}
      {isPagination && (
        <StyledSearchMoreResultsContainer
          role="button"
          onKeyDown={undefined}
          tabIndex={0}
          className={`${selectedIndex === 5 ? 'selected-result' : ''}`}
          onClick={() => {
            dispatch(dataListingsActions.Search(search));
            setData({ count: 0, rows: [] });
            setSearchTerm('');
          }}
        >
          See More
        </StyledSearchMoreResultsContainer>
      )}
    </StyledSearchContainer>
  );
};

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);
  const inputEl: React.MutableRefObject<string | any> = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ count: 0, rows: [] });
  const [dataLoading, setDataLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { rows: dataList } = data;
  const {
    currentDataset: { id: datasetId },
  } = useSelector((state: RootState) => state.userActions);

  const fetchData = async (term: string) => {
    const apiPayload = {
      offset: 0,
      limit: 5,
      search_key: term,
    };
    const response = await dataAPIService.post({ endpoint: `data/${datasetId}`, apiPayload });
    setData(response);
    setDataLoading(false);
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(fetchData, 1000);
  }, []);

  const getSearchTerm = () => {
    const term = inputEl.current.value;
    if (term.length > 2) {
      setDataLoading(true);
      setSearchTerm(term);
      debouncedFetchData(term);
      setIsClicked(false);
    }
  };

  const handleKeyDown = (event: KeyCodeProps) => {
    if (event.keyCode === 13) {
      dispatch(dataListingsActions.Search(searchTerm));
      setIsClicked(true);
      if (selectedIndex === 5) {
        dispatch(dataListingsActions.Search(searchTerm));
        setIsClicked(true);
      } else if (selectedIndex >= 0 && selectedIndex < dataList.length) {
        const selectedResult: searchResultProps = dataList[selectedIndex];
        const pageState = { state: [{ cardId: selectedResult?.id, name: selectedResult?.name }] };
        const pageUrl = `/${selectedResult?.id}/${formatUrl(selectedResult?.name)}`;
        navigate(pageUrl, { ...pageState });
      } else {
        navigate(`?searchQuery=${searchTerm}`);
      }
    } else if (event.keyCode === 40) {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % 6);
    } else if (event.keyCode === 38) {
      setSelectedIndex((prevIndex) => (prevIndex - 1 + 6) % 6);
    }
  };

  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!searchRef.current?.contains(event.target)) {
        setData({ count: 0, rows: [] });
        setIsClicked(true);
      }
    });
    document.addEventListener("keydown", (event: KeyCodeProps) => {
      if (event.keyCode === 13) {
        setData({ count: 0, rows: [] });
        setIsClicked(true);
      }
    });
  }, [dispatch, setData]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchTerm]);

  return (
    <StyledSearchBarContainer ref={searchRef}>
      <StyledSearch>
        <input
          data-testid="search-input"
          ref={inputEl}
          type="text"
          className="search"
          placeholder="Search Bank Name, RSSD ID, Charter Number, Address, City, County"
          onChange={getSearchTerm}
          onKeyDown={handleKeyDown}
        />
        <FiSearch
          data-testid="search-icon"
          className="search-icon"
          onClick={() => {
            dispatch(dataListingsActions.Search(searchTerm));
            setData({ count: 0, rows: [] });
            setIsClicked(true);
            navigate(`?searchQuery=${searchTerm}`);
            setSearchTerm('');
          }}
        />
      </StyledSearch>
      <SearchDropDown
        search={searchTerm}
        dataList={dataList}
        setData={setData}
        isClicked={isClicked}
        handleKeyDown={handleKeyDown}
        selectedIndex={selectedIndex}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        dataLoading={dataLoading}
      />
    </StyledSearchBarContainer>

  );
};

export default Search;
