import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { DataListingsInterface } from "./index.type";
import { post } from "../../api/dataApi";

const initialState: DataListingsInterface = {
  loading: false,
  dataListings: {
    count: 0,
    rows: [],
  },
  error: {},
  apiPayload: {
    offset: 0,
    limit: 15,
    sort: ["property", "total_assets", "DESC NULLS LAST"],
    filters: {},
    search_key: "",
  },
};

export const fetchAsyncDataListings: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("dataListings/fetchAsyncDataListings", post);

const dataListingsSlice = createSlice({

  name: "dataListings",
  initialState,
  reducers: {
    dataListings: (state, action) => {
      state.dataListings.rows.push = action.payload.dataListings;
    },
    pagination: (state, { payload }) => {
      state.apiPayload.offset = (payload);
    },
    Sort: (state, action) => {
      state.apiPayload.sort = action.payload;
    },
    filter: (state, action) => {
      state.apiPayload.offset = 0;
      state.apiPayload.filters = action.payload;
    },
    Search: (state, action) => {
      state.apiPayload.offset = 0;
      state.apiPayload.search_key = action.payload;
    },
    Reset: (state, action) => {
      if (action.payload) {
        state.apiPayload = {
          offset: 0,
          limit: 15,
          sort: ["property", "total_assets", "DESC NULLS LAST"],
          filters: {},
          search_key: "",
        };
      }
    },
  },
  extraReducers: {
    [fetchAsyncDataListings.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncDataListings.fulfilled]: (state, { payload }) => {
      return {
        ...state, dataListings: payload, loading: false, error: {},
      };
    },
    [fetchAsyncDataListings.rejected]: (state, err) => {
      if (err.error.message !== "Aborted") {
        state.loading = false;
        state.error = err.error;
      }
    },
  },
});

export const dataListingsActions = dataListingsSlice.actions;
export default dataListingsSlice.reducer;
