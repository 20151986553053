import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/theme";

const StyledListingDetailContainer = styled.div`
    min-height: 100vh;
    margin: 2%;
    .label {
        color: ${Colors.black};
        font-weight: 600;
        padding-right: 10px;
    }
    .main-tab-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid ${Colors.mildGrey};
    }
    .year-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding-bottom: 5px;
    }
    .nav-tabs .nav-link.active {
        color: ${Colors.orange};
        font-weight: 600;
        font-family: ${FontFamily.sourceSansPro};
        border-bottom: 3px solid ${Colors.orange};
    }
    .nav-tabs .nav-link {
        color: ${Colors.black};
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 15px;
        font-family: ${FontFamily.inherit};
        border: none;
    }
    .nav {
        background-color: ${Colors.white};
    }
    .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
        opacity: 10;
    }
    .tab-container {
        display: flex;
        flex-direction: column;
        gap: 2%;
    }
`;
export default StyledListingDetailContainer;
