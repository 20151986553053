import React from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import StyledPagination from './index.styles';

interface PaginationProps {
    offset: number;
    limit: number;
    count: number;
    action: Record<string, ActionCreatorWithPayload<any, string>>;
}

const Pagination = ({
  offset, limit, count, action,
} : PaginationProps) => {
  const currentPage = Math.floor(offset / limit) + 1;
  const dispatch = useDispatch();
  count = Math.ceil(count / limit);

  const handlePageClick = async (data: { selected: number }) => {
    const page = data.selected + 1;
    const newOffset = (page - 1) * limit;
    dispatch(action.pagination(newOffset));
    window.scroll(0, 0);
  };

  return (
    <StyledPagination data-testid="pagination">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
        pageCount={count}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        forcePage={currentPage - 1}
      />
    </StyledPagination>
  );
};

export default Pagination;
