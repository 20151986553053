import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortOption: localStorage.getItem('sortOption') || 'Highest Asset',
};
const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
      localStorage.setItem('sortOption', action.payload);
    },
  },
});
export const { setSortOption } = sortSlice.actions;
export default sortSlice.reducer;
