import React from 'react';
import StyledFooter from './index.styles';
import { FOOTERCOPYWRITE } from '../../utils/constants';

const index = () => {
  return (
    <StyledFooter>{FOOTERCOPYWRITE}</StyledFooter>
  );
};

export default index;
