import React, { useState } from 'react';
import { ImDownload } from "react-icons/im";
import {
  Button, Menu, MenuItem,
} from '@mui/material';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import JsPDF from 'jspdf';
import { saveAs } from "file-saver";
import StyledExportContainer from './index.styles';
import { ColumnProps } from '../Grid/index.type';
import { Colors, FontFamily } from '../../../utils/theme';
import { DataItem, ExportProps } from './index.type';
import { EXPORTFORMATS } from '../../../utils/constants';
import { replaceBooleanValue, replaceHyphenWithSpace } from '../../../utils/utils';

const generateExportData = (data: DataItem[], columns: ColumnProps[], format: string) => {
  if (format === 'csv') {
    const headers = columns?.map((column: { title: string }) => column?.title).join(',');

    const rows = data?.map((item) => {
      return columns?.map((column: { name: string }) => {
        let value = replaceBooleanValue(item[column?.name]);
        value = replaceHyphenWithSpace(item[column?.name]);

        if (Array.isArray(value)) {
          return `"${value.join(', ')}"`;
        } if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    });

    const csvContent = `${headers}\n${rows.join('\n')}`;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    return blob;
  } if (format === 'xlsx') {
    const dataForExcel = data.map((item) => {
      const excelRow : any = {};

      columns.forEach((column) => {
        const { name } = column;
        let value = replaceBooleanValue(item[column?.name]);
        value = replaceHyphenWithSpace(item[column?.name]);

        if (Array.isArray(value)) {
          excelRow[name] = value.join(', ');
        } else {
          excelRow[name] = value;
        }
      });

      return excelRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(
      dataForExcel,
      { header: columns?.map((column) => column?.title) },
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const arrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    return blob;
  } if (format === 'pdf') {
    const tableHeaders = columns?.map((column: { title: string }) => column?.title);
    const tableData = data?.map((item) => columns?.map((column: { name: string }) => replaceBooleanValue(item[column?.name]) ?? "-"));

    let pdf;
    if (tableHeaders?.length > 10) {
      pdf = new JsPDF('l', 'mm', 'a2');
    } else {
      pdf = new JsPDF();
    }

    autoTable(pdf, {
      head: [tableHeaders],
      body: tableData,
      theme: 'striped',
      styles: { fontSize: 8 },
    });

    return pdf.output('blob');
  }
  throw new Error(`Unsupported format: ${format}`);
};

const Export = ({ rowData, columnData, fileName } : ExportProps) => {
  const [anchorEl, setAnchorEl] = useState<null |
    HTMLElement>(null);

  const [success, setSuccess] = useState(false);

  const handleExportClick = (event:
     React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (label: string, format: string) => {
    handleExportClose();
    const dataBlob = generateExportData(rowData, columnData, format);
    saveAs(dataBlob, `${fileName}.${format}`);
    setSuccess(true);
    toast.success(`Exported as ${label}!`, { autoClose: 2000, hideProgressBar: true });
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <StyledExportContainer>
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        onClick={handleExportClick}
        endIcon={<ImDownload />}
        component={Button}
        className="export-button"
      >
        Export
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleExportClose}
        className="export-menu"
      >
        {EXPORTFORMATS?.map((type) => (
          <MenuItem
            sx={{
              fontSize: 14,
              color: Colors.grey,
              padding: '8px 16px',
              fontFamily: FontFamily.sourceSansPro,
              '&:hover': { backgroundColor: Colors.mildGrey },
            }}
            onClick={() => handleExport(type.label, type.format)}
          >
            Export As
            {' '}
            {type.label}
          </MenuItem>
        ))}
      </Menu>
      {success && <ToastContainer position="top-center" className="custom-toast-container" />}
    </StyledExportContainer>
  );
};

export default Export;
