import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataCard from '../DataCard';
import { RootState } from '../../store';
import NoResultFound from '../../shared/components/NoResultFound';
import { NORESULTFOUNDIMAGE } from '../../utils/Images';
import StyledDataListingContainer from './index.styles';
import CompareModal from '../../pages/DataListing/CompareModal';
import { DataListingProps } from './DataListingType';
import { DataItem } from '../../shared/components/Export/index.type';

const DataListingsTile = ({
  isModalOpen,
  selectedCards,
  handleCardSelect,
  selectedCardObjects,
  handleRemoveCard,
  appliedFilters,
  setAppliedFilters,
  setSelectedFilterChips,
  setSelectedCards,
  setSelectedCardObjects,
} : DataListingProps) => {
  const { dataListings, apiPayload } = useSelector(
    (state: RootState) => state.dataListingsActions,
  );
  const { offset, limit } = apiPayload;
  const { rows: dataList } = dataListings;
  const [parsedCompareData, setParsedCompareData] = useState<DataItem[]>([]);

  useEffect(() => {
    const selectedCompareData = localStorage.getItem("selectedCompareData");
    const parsedData: DataItem[] = selectedCompareData ? [JSON.parse(selectedCompareData)] : [];
    setParsedCompareData(parsedData);
  }, []);

  return (
    <div>
      { dataList?.length === 0 ? <NoResultFound image={NORESULTFOUNDIMAGE} title="No Results Found" />
        : (
          <StyledDataListingContainer>
            {(isModalOpen && offset === 0
              ? (parsedCompareData && [...parsedCompareData, ...dataList].filter(
                (item, index, self) => index === self.findIndex((data) => data.id === item.id)
                && index < limit,
              )) : dataList
            )?.map((dataListing:DataItem) => (
              <DataCard
                key={dataListing?.id}
                id={dataListing?.id}
                name={dataListing?.name}
                locations={dataListing?.locations}
                type={dataListing?.type}
                website={dataListing?.website}
                property={dataListing?.property}
                isSelected={selectedCards?.includes(dataListing?.id)}
                onSelect={handleCardSelect}
                selectedCards={selectedCards}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                setSelectedFilterChips={setSelectedFilterChips}
                setParsedCompareData={setParsedCompareData}
              />
            ))}
            {isModalOpen && (
            <CompareModal
              selectedObjects={selectedCardObjects}
              onRemoveCard={handleRemoveCard}
              setAppliedFilters={setAppliedFilters}
              setSelectedFilterChips={setSelectedFilterChips}
              setSelectedCards={setSelectedCards}
              setSelectedCardObjects={setSelectedCardObjects}
              setParsedCompareData={setParsedCompareData}
            />
            )}
          </StyledDataListingContainer>
        )}
    </div>
  );
};

export default DataListingsTile;
