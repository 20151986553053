import styled from "styled-components";
import { FlexColumnDiv } from "../../utils/ReusableStyles";
import { BreakPoints, Colors } from "../../utils/theme";

const StyledPagination = styled(FlexColumnDiv)`
    .pagination {
        gap: 12px;
        padding-top: 40px;
        color: ${Colors.white01};
    }
    .page-link {
        border-radius: 10px;
        background-color: ${Colors.white01};
        border: none;
        color: ${Colors.orange};
        font-size: 14px;
        font-weight: 600;
    }
    .page-item:first-child .page-link, .page-item:last-child .page-link{
        border-radius: 10px;
        background-color: ${Colors.blueBackground};
        color:  ${Colors.white01};
    }
    .active>.page-link {
        background-color: ${Colors.blueBackground};
        color: ${Colors.white01};
        font-weight: 600;
    }
    .disabled>.page-link {
        opacity: 0.5;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
        .pagination {
            flex-wrap: wrap;
        }
    }
`;

export default StyledPagination;
