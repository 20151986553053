import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import userApiSliceReducer from "./user";
import dataListingsSliceReducer from "./dataListings";
import profileSliceReducer from "./profile";
import branchesSliceReducer from "./branches";
import servicesSliceReducer from "./services";
import mergerSliceReducer from "./merger";
import metricsSliceReducer from "./metrics";
import filterSlice from './filter';
import sortReducer from './sort';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['dataListingsActions'],
};

const rootReducer = combineReducers({
  userActions: userApiSliceReducer,
  dataListingsActions: dataListingsSliceReducer,
  profileActions: profileSliceReducer,
  branchesActions: branchesSliceReducer,
  servicesActions: servicesSliceReducer,
  mergerActions: mergerSliceReducer,
  metricsActions: metricsSliceReducer,
  filterActions: filterSlice,
  sortActions: sortReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export type RootState = ReturnType< typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
