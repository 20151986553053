import styled from "styled-components";
import { FontFamily, Colors, BreakPoints } from "../../utils/theme";
import { FlexColumnDiv, FlexRowDiv } from "../../utils/ReusableStyles";

const StyledDataTitle = styled.div`
    color:  ${Colors.blueSurface};
    width: 70%;
    font-size: 16px;
    font-family: ${FontFamily.inherit};
    font-weight: 500;
    @media (max-width: ${BreakPoints.SmallDevices}) {
        font-size: 14px;
    }
`;

const StyledDataCard = styled(FlexColumnDiv)`
    position: relative;
    align-items: initial;
    justify-content: space-between;
    height: 18rem;
    width: 100%;
    cursor: pointer;
    margin: 5px;
    padding: 16px;
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
    border: solid transparent 1px;
    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    &:hover {
        box-shadow: 0 4px 16px 0 ${Colors.boxShadow};
    }
    .card-link {
        text-decoration: none;
        color: ${Colors.hyperLink};
        font-size: 14px;
    }
    .disabled {
        color: ${Colors.black};
        cursor: not-allowed;
        border: 1px solid grey;
        background-color: ${Colors.grey};
        opacity: 0.5;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) { 
        .content-align {
            flex-direction: column;
        }
        .title-align {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
    }
`;
const StyledSubContent = styled(FlexRowDiv)`
    position: absolute;
    bottom: 53px;
    width: 90%;
    gap: 15px;
    font-size: 14px;
    color: ${Colors.black};
    font-family: ${FontFamily.sourceSansPro};
    font-weight: 500;
    padding-top: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
    border-top: 0.6px solid ${Colors.lightGrey};
    @media (max-width: ${BreakPoints.LargeDevices}) { 
        flex-wrap: wrap;
        gap: 18px;
    }
`;

const StyledProperty = styled(FlexColumnDiv)`
    align-items: center;
    justify-content: start;
    gap: 1px;
    .key-format {
        font-size: 16px;
        color: ${Colors.darkGrey};
    }
`;

const StyledViewButton = styled(FlexRowDiv)`
    position: absolute;
    bottom: 10px;
    width: 90%;
    z-index: 1;
    align-items: initial;
    justify-content: center;
    gap: 5px;
    .format-button {
        font-size: 14px;
        margin: 1px;
        padding: 8px;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) { 
        justify-content: center;
    }
`;

const StyledChip = styled(FlexRowDiv)`
        align-items: start;
        .type-chip {
            color: ${Colors.white};
            background-color: ${Colors.darkOrange};
            font-size: 12px;
            height: fit-content;
        }
        .css-13htn8f-MuiChip-root .MuiChip-avatarSmall {
            height: 16px;
            width: 16px;
        }
        @media (max-width: ${BreakPoints.SmallDevices}) { 
            justify-content : flex-start;
        }
`;

const StyledDataContent = styled.div`
    font-size: 14px;
    color: ${Colors.black};
    font-family: ${FontFamily.sourceSansPro};
    font-weight: 500;
    margin-bottom: 10px;
    word-wrap: break-word;
`;

export {
  StyledDataCard, StyledDataTitle, StyledDataContent, StyledViewButton,
  StyledChip, StyledSubContent, StyledProperty,
};
