import styled from "styled-components";
import { Colors, FontFamily } from "../../../utils/theme";

const StyledExportContainer = styled.div`
    padding-bottom: 1%;
    text-align: end;
    .export-button {
        background-color: ${Colors.white}; 
        color: ${Colors.orange}; 
        border: 1px solid ${Colors.orange}; 
        padding: 8px;
        font-weight: 500;
        height: 4vh;
        font-family: ${FontFamily.sourceSansPro};
    }
    .custom-toast-container {
        text-align: start; 
    }
    .css-9tj150-MuiButton-endIcon {
        margin-right: 0px;
    }
    .css-9tj150-MuiButton-endIcon>*:nth-of-type(1) {
        font-size: 16px;
    }
`;

export default StyledExportContainer;
