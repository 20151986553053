import React from "react";
import Modal from "react-modal";
import { ENV } from "../../../utils/constants";
import { Colors } from "../../../utils/theme";

if (ENV !== 'test') Modal.setAppElement('#root');
interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  modalHeight?: string;
  modalWidth?: string;
}

const ModalComponent: React.FC<ModalProps> = ({
  children, isOpen, modalHeight, modalWidth,
}) => {
  return (
    <Modal
      data-testid="modal"
      style={{
        overlay: {
          backgroundColor: `${Colors.darkSurface}`,
          zIndex: 200,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: modalWidth,
          height: modalHeight,
          borderRadius: "1%",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        },
      }}
      isOpen={isOpen}
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );
};

ModalComponent.defaultProps = {
  modalHeight: "",
  modalWidth: "",
};
export default ModalComponent;
