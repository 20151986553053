import styled from "styled-components";
import { BreakPoints, Colors } from "../../../utils/theme";
import { FlexRowDiv } from "../../../utils/ReusableStyles";

const StyledDropdownDesktop = styled(FlexRowDiv)`
    width: 17rem;
    padding: 0.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    border: none;
    white-space: nowrap;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
    border: 1px solid ${Colors.greyBackground};
`;

const StyledDropDown = styled.div`
  .dropdown-button {
    font-weight: 600;
    margin-left: 0.5rem;
  }
  .drop-left {
    display: flex;
    margin-left: 12px;
    font-size: 15px;
  }
  .drop-text {
    color: ${Colors.orange};
  }
  .dropdown-icon {
    font-size: 2rem;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
  }
`;

const StyledDropdownList = styled(FlexRowDiv)`
    justify-content: space-between;
    padding: 4%;
    width: 17rem;
    font-size: 14px;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
    &:hover {
      background-color: ${Colors.interactiveBackground};
    }
`;

const StyledMobileLabel = styled(FlexRowDiv)`
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 4%;
    text-align: left;
    color: ${Colors.text};
`;

const StyledDropDownContainer = styled.div`
  .tick-mark {
    position: absolute;
    width: 30px;
    height: 20px;
    object-fit: contain;
  }
  .dropdown-field {
    position: absolute;
    z-index: 100;
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
  }
  .inactive-field {
    margin-left: 2.5rem;
  }
  .active-field {
    margin-left: 2.5rem;
    font-weight: 600;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: block;
    .dropdown-field-button {
      width: 100%;
      height: 8vh;
      padding: 1% 2%;
      font-size: 1rem;
    }
    .dropdown-field {
      width: 100%;
      margin-top: 0.4rem;
      height: 15%;
    }
  }
`;

const StyledDropDownButton = styled.div`
  display: none;
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: block;
  }
`;

export {
  StyledDropDownContainer, StyledDropdownDesktop, StyledDropDown,
  StyledDropDownButton, StyledDropdownList, StyledMobileLabel,
};
