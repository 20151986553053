import React from 'react';
import { formatChartLabel, formatMonthYear } from '../../utils/utils';
import { DataItem } from '../../shared/components/Export/index.type';
import { StyledChartTitle, StyledDataChart } from './index.styles';
import Chart from '../../shared/components/Chart';
import ChartLabel from '../../shared/components/Chart/ChartLabel';

export const formatChartData = (content: DataItem) => {
  const filteredData = Object.keys(content).reduce((result: DataItem, key) => {
    result[key] = content[key]
      .map((obj: DataItem) => {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([prop, value]: string[]|any) => (
              value !== null && value !== "0.00" && value !== 0
                && (prop.includes("Analytics") || prop.includes("%"))
            )),
        );
      })
      .filter((obj: DataItem) => Object.keys(obj).length > 0);

    return result;
  }, {});

  const transformedChartData: DataItem = {};
  const keys = Object.keys(filteredData[Object.keys(filteredData)[0]][0]);

  keys.forEach((key) => {
    transformedChartData[key] = {};
    Object.keys(filteredData).forEach((itemId) => {
      const item = filteredData[itemId];
      transformedChartData[key][itemId] = Array.isArray(item)
        ? item.map((subItem) => subItem[key])
        : item[key];
    });
  });

  return transformedChartData;
};

const ChartData = ({ content, tabName }: { content: DataItem, tabName: string}) => {
  const firstKey = Object.keys(content)[0];
  const reportedDates = content[firstKey]?.map((item: DataItem) => item["Reported Date"]);
  const formattedDate = reportedDates?.map((dateStr: string) => {
    return formatMonthYear(dateStr, "en-US");
  });
  const formattedChartData = formatChartData(content);
  const keyLabels = Object.keys(content);

  return (
    <>
      <StyledChartTitle>
        PEER ANALYTICS -
        {' '}
        {tabName}
      </StyledChartTitle>
      <ChartLabel data={keyLabels} />
      <StyledDataChart>
        {Object.entries(formattedChartData).map(([key, value]) => (
          <div key={key} className="chart-container">
            <div className="peer-label-name">{formatChartLabel(key)}</div>
            {key.includes('(%)') ? (
              <Chart chartType="line" data={value} label={formattedDate} />
            ) : (
              <Chart chartType="bar" data={value} label={formattedDate} />
            )}
          </div>
        ))}
      </StyledDataChart>
    </>
  );
};

export default ChartData;
