import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { Service } from "./index.type";
import { get } from "../../api/dataApi";

export const fetchAsyncServices: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("services/fetchAsyncServices", get);

const initialState : Service = {
  services: {
    "Personal Services": [""],
    "Business Services": [""],
  },
  loading: false,
  error: {},
};
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchAsyncServices.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncServices.fulfilled]: (state, { payload }) => {
      return { ...state, services: payload, loading: false };
    },
    [fetchAsyncServices.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const servicesActions = servicesSlice.actions;
export default servicesSlice.reducer;
