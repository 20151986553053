import axios from "axios";
import { APIHEADERS, AUTHAPIURL } from "../utils/constants";

const post = async (endpoint: string, authorization: boolean, body: object) => {
  const token = localStorage.getItem('token');
  const result = axios.post(`${AUTHAPIURL}/${endpoint}`, body, {
    headers: {
      ...APIHEADERS,
      Authorization: authorization ? `Bearer ${token}` : "No Auth",
    },
  }).catch((error) => {
    return error?.response;
  });
  return result;
};

const get = async (endpoint: string) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${AUTHAPIURL}/${endpoint}`, {
    headers: {
      ...APIHEADERS,
      Authorization: `Bearer ${token}`,
    },
  }).catch((err) => err.response);
  return response;
};

export {
  post,
  get,
};
