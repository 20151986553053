import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/theme";
import { TitleDiv } from "../../utils/ReusableStyles";

const StyledTitle = styled.div`
    font-weight: 600;
    font-size: 1.15rem;
    text-align: inherit;
    &.hide-mobile {
        @media (max-width: ${BreakPoints.SmallDevices}) {
            display : none;
        }
    }
`;

const StyledSubTitle = styled.div`

`;

const StyledDataTitle = styled(TitleDiv)`
    font-size: 29px;
    width: 35vw;
    padding-top: 35px;
    cursor: pointer;
    color: ${Colors.darkGrey};
    @media (max-width: ${BreakPoints.SmallDevices}) {
        font-size: 20px;
    }
`;

export { StyledTitle, StyledSubTitle, StyledDataTitle };
