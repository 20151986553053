import React, { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { AUTHURL } from './constants';
import { fetchAsyncUser } from '../store/user';

const withAuthentication = (Component : FC) => {
  const AuthProvider = (props : object) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['accessToken']);
    const { accessToken } = cookies;
    if (!accessToken) {
      window.location.href = AUTHURL || '/login';
    }

    useEffect(() => {
      if (!accessToken) {
        window.location.href = AUTHURL || '/login';
      }
      if (accessToken) {
        localStorage.setItem('token', accessToken);
        dispatch(fetchAsyncUser("user"));
      }
    }, [accessToken]);
    return <Component {...props} />;
  };

  return AuthProvider;
};

export default withAuthentication;
