import styled from "styled-components";
import { Colors, FontFamily } from "../../../utils/theme";
import { FlexRowDiv } from "../../../utils/ReusableStyles";

const StyledContainer = styled.div`
    margin: 2% 2%;
`;
const StyledTableTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: ${Colors.blueSurface};
    padding-bottom: 20px;
`;
const StyledHeader = styled(FlexRowDiv)`
    justify-content: space-between;
    padding: 2% 2% 0% 2%;
`;
const StyledTableHeading = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: ${Colors.blueSurface};
    font-family: ${FontFamily.sourceSansPro};
    padding-bottom: 10px;
`;
const StyledTableContainer = styled(FlexRowDiv)`
    margin: 1% 2%;
    align-items: start;
    justify-content: start;
    gap: 3%;
`;
const StyledMultiLevelTableContainer = styled.div`
    width: 50%;
`;

export {
  StyledContainer, StyledTableTitle, StyledTableContainer,
  StyledTableHeading, StyledMultiLevelTableContainer, StyledHeader,
};
