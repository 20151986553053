import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/theme";
import { FlexColumnDiv, FlexRowDiv } from "../../utils/ReusableStyles";

const StyledSearchContent = styled(FlexColumnDiv)`
    align-items: start;
    justify-content: start;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
    padding: 1em;
    &:hover {
        background-color: ${Colors.subduedSurface};
    }
`;

const StyledSearchMoreResultsContainer = styled.div`
    border: none;
    color: ${Colors.orange};
    padding: 1em;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-align: start;
    background-color: ${Colors.lightSurface};
    &:hover {
        background-color: ${Colors.subduedSurface};
    }
`;

const StyledSearch = styled(FlexRowDiv)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    border: 1px solid ${Colors.grey20};
    padding: 0.5%;
    height: 100%;
`;

const StyledSearchBarContainer = styled.div`
    width: 50%;
    position: relative;
    .search {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        padding-left: 10px;
        font-weight: 400;
    }
    .link-text {
        text-decoration: none;
    }
    .name-text {
        color: ${Colors.darkGrey};
        font-weight: 600;
        font-size: 14px;
    }
    .location-text {
        font-weight: 600;
        color: ${Colors.grey};
        font-size: 13px;
    }
    .no-result {
        padding: 1%;
        font-size: 14px;
        background-color: ${Colors.white};
        color: ${Colors.darkGrey};
    }
    .search-icon {
        color: ${Colors.grey};
        stroke-width: 2;
        padding-left: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
        width: 100%;
        margin-bottom: 0%;
    }
`;

const StyledSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 80;
    width: 100%;
    box-shadow: 0 1px 2px 0 ${Colors.boxShadow};
    .selected-result {
        background-color: ${Colors.grey90};
    }
`;

export {
  StyledSearchBarContainer, StyledSearch, StyledSearchMoreResultsContainer,
  StyledSearchContent, StyledSearchContainer,
};
