import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../../utils/theme";

interface Props {
  fontSize? : string;
}

const StyledLink = styled.a<Props>`
  font-family: ${FontFamily.sourceSansPro};
  font-size: ${(props) => props.fontSize}px;
  white-space: nowrap;
  line-height: 1.2;
  color: ${Colors.blueBackground};
  text-decoration: none;
  cursor: pointer;
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    font-size: 13px;
    flex-wrap: wrap;
  }
`;

export default StyledLink;
