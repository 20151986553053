import styled from "styled-components";
import { Colors } from "../../../utils/theme";

const StyledSwitch = styled.div`
    .switch-container {
        display: flex;
        width: 100%;
        border: 1px solid ${Colors.orange};
        border-radius: 5px;
    }
    .switch-button {
        color: ${Colors.orange};
        cursor: pointer;
        padding: 4px;
        font-size: 14px;
        width: 70px;
        height: 4vh;
        border-radius: 5px;
    }
    .switch-button.table-active {
        background-color: ${Colors.orange};
        border-radius: 3px 0px 0px 3px;
        color: ${Colors.white};
    }
    .switch-button.graph-active {
        border-radius: 0px 3px 3px 0px;
        color: ${Colors.white};
        background-color: ${Colors.orange};
    }
`;
export default StyledSwitch;
