import React, { ReactNode } from "react";

const AUTHAPIURL: string | undefined = process.env.REACT_APP_AUTH_API;
const AUTHURL: string | undefined = process.env.REACT_APP_AUTH_URL;
const DATAAPIURL : string | undefined = process.env.REACT_APP_DATA_API;
const DOMAIN: string | undefined = process.env.REACT_APP_DOMAIN;
const ENV: string | undefined = process.env.NODE_ENV;
const APIHEADERS = {
  Accept: 'application/json',
};
const FOOTERCOPYWRITE = "© 2023 Sastah. All rights reserved.";
const PASSWORDSMISMATCH = {
  type: "manual",
  message: "Passwords do not match",
};
const FORGETPASSWORDERRORS: {
  [key: string]: string | ReactNode;
} = {
  "Invalid code provided, please request a code again.":
  <>
    Verification link has been used already, Please login
    {' '}
    <a href="/login">here</a>
    {' '}
    or reset your password again
    {' '}
    <a href="/forgot-password">here</a>
    .
  </>,
  "Invalid verification code provided, please try again.":
  <>
    Verification link expired, please try again
    {' '}
    <a href="/forgot-password">here</a>
    .
  </>,
  "Attempt limit exceeded, please try after some time.": "Forgot Password attempt limit exceeded, please try after some time.",
};
const PASSWORDVALIDATIONMESSAGES = {
  isMinLengthValid: 'Must contain at least 8 characters',
  isUppercaseValid: 'Must contain at least one letter in upper case [A-Z]',
  isLowercaseValid: 'Must contain at least one letter in lower case [a-z]',
  isNumberValid: 'Must contain at least one number [0-9]',
  isSpecialCharValid: 'Must contain at least one special character [!@#$&]',
};

const EXPORTFORMATS = [
  { label: 'CSV', format: 'csv' },
  { label: 'PDF', format: 'pdf' },
  { label: 'Excel', format: 'xlsx' },
];
const ACCESSDENIEDMESSAGE = "You are not authorized to access our dataset.";
const CONTACTSUPPORTMESSAGE = "Please contact support.";
export {
  AUTHURL,
  AUTHAPIURL,
  DATAAPIURL,
  DOMAIN,
  ENV,
  APIHEADERS,
  PASSWORDSMISMATCH,
  FOOTERCOPYWRITE,
  FORGETPASSWORDERRORS,
  PASSWORDVALIDATIONMESSAGES,
  EXPORTFORMATS,
  ACCESSDENIEDMESSAGE,
  CONTACTSUPPORTMESSAGE,
};
