
const Colors = {
  lightGrey: "#e4e0e0",
  white01: "#fafafa",
  white02: "#fdfcfb",
  orange: "#f48252",
  text01: "#f48252",
  grey: "#818080",
  background: "#232F3E",
  subduedSurface: "#F7F7F7",
  black: "#111010",
  darkGrey: "#5A5A5A",
  subduedText: "#6F7781",
  mildGrey: "#e8e8e8",
  blueSurface: "#0073A8",
  greyBackground: "#DDDDDD",
  grey90: "#e8e7ea",
  successText: "#1CB01C",
  errorText: "rgb(168, 35, 51)",
  boxShadow: "rgba(0, 0, 0, 0.2)",
  grey05: "#F0F2F5",
  grey70: "#3F4D5A",
  grey20: "#C3CCD5",
  tableBorder: "#D3D3D3",
  lightSurface: "#FFFFFF",
  white: "#ffffff",
  tile: "#fbfbfc",
  interactiveBackground: "#F6FAFD",
  blueBackground: "#2992c2",
  grey30: "#A5B2C0",
  grey10: "#E1E5EA",
  darkSurface: "#454545",
  text: "#0E1720",
  darkOrange: "#FF9900",
  hyperLink: "#016ec5",
  lightGreyBackground: "#f3f3f3",
  lightGreen: "rgba(156, 230, 36, 0.5)",
  lightRed: "rgba(244, 130, 82, 0.8)",
  lightBlue: "rgba(25, 165, 216, 0.6)",
  royalBlue: "#0000FF",
};
const BreakPoints = {
  SmallDevices: "576px",
  MediumDevices: "770px",
  LargeDevices: "992px",
  ExtraLargeDevices: "1200px",
};
const FontFamily = {
  sourceSansPro: "'Source Sans Pro', sans-serif",
  inherit: "inherit",
};

export {
  Colors, BreakPoints, FontFamily,
};
